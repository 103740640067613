<template>
  <f7-page class="search-map">
    <f7-navbar>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Указать на карте</f7-nav-title>
      <f7-nav-right>
        <f7-link v-on:click="selectLoactionReturn" panel-open="right"
          >Выбрать</f7-link
        >
      </f7-nav-right>
      <f7-subnavbar :inner="false">
        <f7-searchbar
          placeholder="Где вы находитесь?"
          v-on:input="search"
          v-on:click:clear="clear"
          v-on:change="change"
          :value="searchBarValue"
          search-container=".search-list"
          search-in=".item-title"
        ></f7-searchbar>
      </f7-subnavbar>
    </f7-navbar>
    <div class="search-location">
      <f7-button
        v-for="location in locationArray"
        v-on:click="selectLoactionClick(split(location.value))"
        fill
        :key="location"
        >{{ split(location.value) }}</f7-button
      >
    </div>
    <!--        <div class="my-position" v-on:click="myPosition()">-->
    <!--            <f7-icon fa="location-arrow"></f7-icon>-->
    <!--        </div>-->
    <!--        <yandex-map :zoom="12" :controls="controls" :coords="[43.123076, 131.924487]" :settings="settings">-->
    <!--            <ymap-marker-->
    <!--                    :coords="[43.123076, 131.924487]"-->
    <!--                    marker-id="123"-->
    <!--                    hint-content="some hint"-->
    <!--            />-->
    <!--        </yandex-map>-->
  </f7-page>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import ymaps2 from "ymaps";
export default {
  name: "selectMap",
  props: ["services", "description"],
  // eslint-disable-next-line vue/no-unused-components
  components: { yandexMap, ymapMarker },
  data: function () {
    return {
      map: null,
      myPlacemark: null,
      myPlacemarkLocation: null,
      searchControl: null,
      ymaps: null,
      searchBarValue: "",
      locationArray: [],
    };
  },
  created() {
    this.controls = [];
    this.settings = {
      apiKey: "28e104e6-a8f5-4a5e-a338-1ee0b90821e0",
      lang: "ru_RU",
      coordorder: "latlong",
      version: "2.1",
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      setTimeout(() => {
        ymaps2
          .load(
            "https://api-maps.yandex.ru/2.1/?apikey=28e104e6-a8f5-4a5e-a338-1ee0b90821e0&lang=ru_RU"
          )
          // eslint-disable-next-line no-unused-vars
          .then((maps) => {
            let div = this.$el.querySelector("#map_canvas");
            // eslint-disable-next-line no-undef
            this.map = new ymaps.Map(div, {
              center: [43.123076, 131.924487],
              zoom: 12,
            });
            this.map.events.add("click", (e) => {
              let coords = e.get("coords");
              // Если метка уже создана – просто передвигаем ее.
              if (this.myPlacemarkLocation) {
                this.myPlacemarkLocation.geometry.setCoordinates(coords);
              }
              // Если нет – создаем.
              else {
                this.myPlacemarkLocation = this.createPlacemark(coords);
                this.map.geoObjects.add(this.myPlacemarkLocation);
                // Слушаем событие окончания перетаскивания на метке.
                this.myPlacemarkLocation.events.add("dragend", () => {
                  this.getAddress(
                    this.myPlacemarkLocation.geometry.getCoordinates()
                  );
                });
              }
              this.getAddress(coords);
            });
            this.map.controls.remove("zoomControl");
            this.map.controls.remove("geolocationControl");
            this.map.controls.remove("routeButtonControl");
            this.map.controls.remove("inputSearch");
            this.map.controls.remove("trafficControl");
            this.map.controls.remove("rulerControl");
            this.map.controls.remove("typeSelector");
            this.map.controls.remove("fullscreenControl");
            this.map.controls.remove("searchControl");
          })
          .catch((error) => console.log("Failed to load Yandex Maps", error));
      }, 200);
    });
  },
  methods: {
    change() {
      setTimeout(() => this.selectLoactionReturn(), 200);
    },
    myPosition() {
      let onSuccess = (position) => {
        console.log([+position.coords.latitude, +position.coords.longitude]);
        // eslint-disable-next-line no-undef
        this.myPlacemark = new ymaps.Placemark();
        this.myPlacemark.geometry.setCoordinates([
          +position.coords.latitude,
          +position.coords.longitude,
        ]); // устанавливаем метке координаты, высчитанные из положения клика
        this.map.geoObjects.add(this.myPlacemark); // ставим метку на карту
      };
      // onError Callback receives a PositionError object
      //
      function onError(error) {
        alert(
          "code: " + error.code + "\n" + "message: " + error.message + "\n"
        );
      }
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    },
    search(query) {
      if (query.srcElement.value.length > 2) {
        // eslint-disable-next-line no-undef
        ymaps
          .suggest("Россия, Владивосток " + query.srcElement.value + "")
          .then((items) => {
            console.log(items);
            this.locationArray = items;
          });
      }
    },
    split(str) {
      let newStr = str.split(",");
      let strReturn = "";
      delete newStr[0];
      delete newStr[1];
      delete newStr[2];
      newStr.forEach((value) => {
        strReturn += value;
      });
      return strReturn;
    },
    clear() {
      this.locationArray = [];
    },
    selectLoactionClick(value) {
      this.searchBarValue = value;
      this.locationArray = [];
    },
    selectLoactionReturn() {
      this.$f7router.navigate("/", {
        props: {
          address: this.searchBarValue,
          services: this.services,
          description: this.description,
        },
      });
    },
    createPlacemark(coords) {
      // eslint-disable-next-line no-undef
      return new ymaps.Placemark(
        coords,
        {
          iconCaption: "поиск...",
        },
        {
          preset: "islands#violetDotIconWithCaption",
          draggable: true,
        }
      );
    },
    // Определяем адрес по координатам (обратное геокодирование).
    getAddress(coords) {
      this.myPlacemarkLocation.properties.set("iconCaption", "поиск...");
      this.ymaps.geocode(coords).then((res) => {
        let firstGeoObject = res.geoObjects.get(0);
        this.searchBarValue = this.split(firstGeoObject.getAddressLine());
        this.myPlacemarkLocation.properties.set({
          // Формируем строку с данными об объекте.
          iconCaption: [
            // Название населенного пункта или вышестоящее административно-территориальное образование.
            firstGeoObject.getLocalities().length
              ? firstGeoObject.getLocalities()
              : firstGeoObject.getAdministrativeAreas(),
            // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
            firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
          ]
            .filter(Boolean)
            .join(", "),
          // В качестве контента балуна задаем строку с адресом объекта.
          balloonContent: this.split(firstGeoObject.getAddressLine()),
        });
      });
    },
  },
};
</script>

<style>
.ymap-container {
  width: 100%;
  height: 100%;
}

.md .searchbar input[type="search"],
.md .searchbar input[type="text"] {
  color: black !important;
}
.search-map .my-position {
  margin-top: 50px;
  position: absolute;
  bottom: 50px;
  right: 20px;
  width: 32px;
  z-index: 2;
  background: #0aaaa0;
  border-radius: 30px;
  text-align: center;
  padding: 6px;
  font-size: 22px;
  color: white;
}
.search-map #map_canvas {
  /* Must bigger size than 100x100 pixels */
  width: 100%;
  height: 100%;
}
.search-map .search-location {
  margin-top: 15px;
  position: absolute;
  z-index: 2;
  width: 100%;
}
.search-map .search-location a.button.button-fill {
  margin-bottom: 6px;
  font-size: 15px;
  text-transform: capitalize;
  text-align: left;
  border-radius: 0;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 25px;
}
</style>
