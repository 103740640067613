<template>
  <f7-page :page-content="false">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Владивосток</div>
      <f7-nav-right>
        <switchPushNotifications></switchPushNotifications>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar v-if="user != null && user.status == 2">
      <f7-link tab-link="#tab-index-1" tab-link-active>Заказчик</f7-link>
      <f7-link tab-link="#tab-index-2"
        >Заказы</f7-link
      >
    </f7-toolbar>
    <f7-tabs swipeable id="index-tabs">
      <f7-tab id="tab-index-1" class="page-content" tab-active>
        <f7-card>
          <f7-card-header>Оставить заявку</f7-card-header>
          <f7-card-content>
            <f7-list no-hairlines-md>
              <f7-list-input
                label="Категория"
                type="select"
                defaultValue="0"
                :value="orderAdd.services"
                @input="orderAdd.services = $event.target.value"
                placeholder="Пожалуйста, выберите..."
              >
                <f7-icon icon="flaticon-list" slot="media"></f7-icon>
                <option value="0">Выберите категорию</option>

                <template v-if="order.services.length !== 0">
                  <option
                    v-for="(service, serviceKey) in order.services"
                    :selected="service.id == orderAdd.services"
                    :value="service.id"
                    :key="serviceKey"
                  >
                    {{ service.name }}
                  </option>
                </template>
              </f7-list-input>
              <f7-list-input
                label="Комментарий"
                type="textarea"
                clear-button
                :value="orderAdd.description"
                @input="orderAdd.description = $event.target.value"
                placeholder="Дополнительная информация"
              >
                <f7-icon icon="flaticon-info" slot="media"></f7-icon>
              </f7-list-input>
              <div v-on:click="selectMap">
                <f7-list no-hairlines-md>
                  <f7-list-input
                    label="Адрес"
                    type="text"
                    :disabled="true"
                    placeholder="Введите адрес"
                    :value="orderAdd.position"
                    @input="orderAdd.position = $event.target.value"
                    clear-button
                  >
                    <f7-icon
                      icon="flaticon-placeholders"
                      slot="media"
                    ></f7-icon>
                    <div
                      class="button button-round my-locations"
                      style="width: 30%; border: 1px solid"
                    >
                      <i class="flaticon-placeholders"></i>
                    </div>
                  </f7-list-input>
                </f7-list>
              </div>
            </f7-list>
          </f7-card-content>
          <div style="margin: 10px; padding-bottom: 10px">
            <f7-button v-on:click="addOrder" fill round
              >Подать заявку</f7-button
            >
          </div>
          <div style="margin: 10px; padding-bottom: 10px">
            <f7-button
              href="tel:89084404911"
              fill
              round
              color="red"
              class="external"
              >SOS</f7-button
            >
          </div>
        </f7-card>
      </f7-tab>
      <Orders v-if="user != null && user.status == 2"></Orders>
    </f7-tabs>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";
import Orders from "../component/orders.vue";
import switchPushNotifications from "../component/switchPushNotifications.vue";

export default {
  name: "index",
  props: ["navigate", "address", "services", "description"],
  components: {
    Orders,
    switchPushNotifications,
  },
  data: () => {
    return {
      user: ApiService.userLocal(),
      order: {
        services: [],
        execute: null,
      },
      orderAdd: {
        services: null,
        description: "",
        position: "",
      },
      index: "tab-index-1",
      redy: false,
      intervalPosition: null,
    };
  },
  created: function () {
    console.log(this.user.status); //  && user.status != 2
    this.orderAdd.position = this.address;
    this.orderAdd.services = this.services;
    this.orderAdd.description = this.description;
    ApiService.user();
    // console.log(this.order);
    this.getIndex();
    if (this.$isMobile()) {
      console.log(this.$isMobile());
      this.sendMsgToFlutter();
    }
    // this.intervalPosition = setInterval(() => {
    // 	let onSuccess = (position) => {
    // 		console.log([+position.coords.latitude, +position.coords.longitude]);
    // 	};
    // 	navigator.geolocation.getCurrentPosition(onSuccess, error => {
    // 		console.log('code: '    + error.code    + '\n' +
    // 			'message: ' + error.message + '\n');
    // 		clearInterval(this.intervalPosition);
    //     });
    // }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.intervalPosition);
  },
  methods: {
    sendMsgToFlutter() {
      let user = ApiService.user();
      if (user != undefined) {
        // console.log(user.data.user);
        window.Toaster.postMessage(user.id);
      }
    },
    log(data) {
      console.log(data);
    },
    addOrder: function () {
      ApiService.addOrder(
        this.orderAdd.description,
        this.orderAdd.services,
        this.orderAdd.position
      )
        .then((response) => {
          this.$f7router.navigate("/my-orders/");
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelOrder(id) {
      ApiService.cancelOrder(id)
        .then((response) => {
          if (response == 1) {
            this.$f7.dialog.alert(
              "Заявка успешно отменена",
              "Уведомления",
              () => {
                this.getIndex();
              }
            );
          }
        })
        .catch(() => {});
    },
    orderSuccess(id) {
      ApiService.orderSuccess(id)
        .then((response) => {
          if (response == 1) {
            this.$f7.dialog.alert("Заявка выполенна", "Уведомления", () => {
              this.getIndex();
            });
          }
        })
        .catch(() => {});
    },
    getIndex() {
      this.$f7.dialog.preloader("Пожалуйста подождите");
      ApiService.getIndex()
        .then(async (response) => {
          this.order.services = await ApiService.getService();
          this.order.execute = response.execute;
          this.$f7.dialog.close();
          if (this.navigate) {
            this.$f7.tab.show("#tab-index-2");
          }
        })
        .catch(() => {
          this.$f7.dialog.close();
        });
    },
    chatPage(id) {
      this.$f7router.navigate("/chat/", {
        props: {
          navigate: id,
        },
      });
    },
    selectMap() {
      console.log("select-map");
      this.$f7router.navigate("/select-map/", {
        props: {
          services: this.orderAdd.services,
          description: this.orderAdd.description,
        },
      });
    },
  },
};
</script>

<style scoped>
  #index-tabs {
    text-align: left;
  }
</style>
