<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link class="panel-open" open-panel="left" icon="fa fa-bars"></f7-link>
      </f7-nav-left>
      <div class="title">Регистрация</div>
    </f7-navbar>
    <f7-list no-hairlines-md>
      <f7-list-input type="text" placeholder="Ваше имя" info="Введите Ваше полное имя" clear-button required validate
        :value="form.name" @input="form.name = $event.target.value"></f7-list-input>
      <f7-list-input type="tel" placeholder="Телефон" info="Ваш телефон пример (9998887766) без (+7, 8)" clear-button
        required validate max="10" pattern="[0-9]*" data-error-message="Введите Телефон!" :value="form.phone" @input="($event) => {
          form.phone = $event.target.value;
          form.email = $event.target.value + '@mail.ru';
        }
          "></f7-list-input>
      <f7-list-input type="password" placeholder="Пароль" info="Ваш пароль" clear-button required validate max="10"
        pattern="[0-9]*" data-error-message="Введите пароль!" :value="form.password" @input="($event) => {
          form.password = form.password_confirmation = $event.target.value;
        }
          "></f7-list-input>
      <f7-row>
        <f7-col width="50">
          <f7-list-input type="text" placeholder="*******" info="Введите код" pattern="[0-9]*"
            data-error-message="Введите Код!" clear-button required validate :value="phone_code_confirm"
            @input="phone_code_confirm = $event.target.value"></f7-list-input>
        </f7-col>
        <f7-col width="50">
          <f7-button id="sendSmsBtn" style="margin-right: 16px" big fill v-on:click="sendCode()">Получить
            код</f7-button>
        </f7-col>
      </f7-row>
    </f7-list>
    <f7-block>
      <f7-row tag="p">
        <f7-col tag="span">
          <f7-button big fill v-on:click="submit_register(form)">Зарегистрироваться</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";
import store from "../../vuex/storage.js";

export default {
  name: "register",
  data() {
    return {
      form: {
        name: "",
        email: "mail@m.ru",
        phone: "",
        password: "",
        state_number: "А000АА25",
        car_model_id: "1",
        password_confirmation: "",
      },
      marks: {},
      models: {},
      phone_code: null,
      phone_code_confirm: null,
      code: null,
    };
  },
  created() {
    ApiService.getAutoMarks().then((res) => {
      this.marks = res;
      //console.log(this.marks);
    });
  },
  methods: {
    set_mark(ev) {
      let id = ev.srcElement.value;
      ApiService.getAutoModels(id).then((res) => {
        this.models = res;
        console.log(this.models);
      });
    },
    set_model(ev) {
      this.form.car_model_id = ev.srcElement.value;
    },
    submit_register(form_data) {
      let form_data_new = { ...form_data };
      form_data_new.phone = form_data_new.phone.replace(/[^+\d]/g, "");
      if (form_data_new.phone.length > 10) {
        this.$f7.dialog.confirm(
          " Введен не верный номер телефона телефон должен быть без пробелов а так же без восьмерки " +
          "пример (9998887766)",
          "Телефон"
        );
        return 0;
      }
      if (!(this.phone_code == this.phone_code_confirm)) {
        this.$f7.dialog.confirm(
          "Кода подтверждения",
          "Не верный код подтверждения."
        );
        return 0;
      }
      this.$f7.dialog.preloader("Пожалуйста подождите");
      form_data_new.phone = "7" + form_data_new.phone;
      ApiService.submitRegistration(form_data_new)
        .then((res) => {
          if (res.errors != null) {
            this.$f7.dialog.close();
            let errorsString = "";
            res.errors.forEach((item) => {
              errorsString += item + "\n";
            });
            this.$f7.dialog.alert(errorsString, "Ошибка");
          } else {
            ApiService.login(form_data_new.phone, this.form.password)
              .then(() => {
                this.$f7.dialog.close();
                this.$f7router.navigate("/", { reloadAll: true });
                store.dispatch("chancgeLoginStatus");
              })
              .catch((error) => {
                this.$f7.dialog.close();
                console.log(form_data_new.phone, this.form.password);
                if (error.status == 401) {
                  this.$f7.dialog.alert("Неверный логин или пароль", "Ошибка");
                }
              });
          }
        })
        .catch(() => {
          this.$f7.dialog.close();
          this.$f7.dialog.alert("Введеные данные не корректные!", "Ошибка");
        });
    },
    formatPhoneNumber(input) {
      // Убираем всё, кроме цифр
      let cleaned = input.replace(/\D/g, '');

      // Проверяем и форматируем номер
      if (cleaned.startsWith('8') && cleaned.length === 11) {
        cleaned = '7' + cleaned.slice(1);
      } else if (cleaned.startsWith('9') && cleaned.length === 10) {
        cleaned = '7' + cleaned;
      } else if (cleaned.startsWith('7') && cleaned.length === 11) {
        // Уже в нужном формате
      } else {
        throw new Error('Некорректный номер телефона');
      }

      return cleaned;
    },
    async sendCode() {
      this.$f7.dialog.alert(
        "Код отправлен. (последнии 4 цифры номера)",
        "Ожидайте звонка"
      );
      const phone = this.formatPhoneNumber(this.form.phone);
      console.log("Input phone:", phone);
      const url = new URL("https://tube-city-call.pileskyd.ru/api/call");
      url.searchParams.set("phone", phone);
      const response = await fetch(url.toString());
      const data = await response.json();

      if (data.status === "ERROR") {
        this.$f7.dialog.confirm(
          "Ошибка отправки",
          "Здравствуйте, если Вам не поступает звонок обратитесь к администратору!"
        );

        return;
      }
      this.phone_code = data.code;
    },
  },
};
</script>

<style scoped>
.list li {
  list-style-type: none;
}
</style>
