<template>
  <f7-page>
    <f7-navbar title="Новости" back-link="Back" sliding></f7-navbar>
    <f7-card
      class="demo-facebook-card"
      v-for="new_item in news"
      v-bind:key="new_item"
    >
      <f7-card-content>
        <h5>{{ new_item.title }}</h5>
        <img :src="url + new_item.img" width="100%" />
      </f7-card-content>
      <f7-card-footer class="no-border">
        <f7-link v-on:click="show_details_news(new_item.id)"
          >Читать далее</f7-link
        >
      </f7-card-footer>
    </f7-card>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";
export default {
  name: "blog",
  data() {
    return {
      news: {},
      url: "http://tube-city.ru/img/post/",
    };
  },
  created() {
    ApiService.getNews().then((res) => {
      this.news = res;
    });
  },
  methods: {
    show_details_news(id) {
      this.$f7router.navigate("/blog-details/", {
        props: {
          navigate: id,
        },
      });
    },
  },
};
</script>

<style scoped>
</style>