<template>
  <f7-tab id="tab-index-2" class="page-content">
    <template v-if="sortOrders(data.orders)">
      <f7-card v-bind:key="orderKey" v-for="(order, orderKey) in data.orders">
        <f7-card-header>
          <span style="font-size: 12px"
            >Дата {{ order.created_at | moment("DD.MM | HH:mm") }}</span
          >
        </f7-card-header>
        <f7-card-content :padding="false">
          <f7-list medial-list>
            <f7-list-item
              header="Адрес"
              :title="order.data.geolocation"
              subtitle="Beatles"
            >
              <f7-icon slot="media" icon="fa fa-map-marker"></f7-icon>
            </f7-list-item>
            <f7-list-item
              header="Категория"
              :title="order.service.name"
              subtitle="Beatles"
            >
              <f7-icon slot="media" icon="fa fa-th"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-card-content>
        <f7-card-content v-html="order.description"> </f7-card-content>
        <f7-card-footer>
          <template v-if="order.status == 1">
            <f7-link v-on:click="servicesAccept(order.id)">Принять</f7-link>
          </template>
          <template v-if="order.status == 3">
            <h5>Заявка выполнена</h5>
          </template>
        </f7-card-footer>
      </f7-card>
    </template>
  </f7-tab>
</template>

<script>
import ApiService from "../common/api.service.js";

export default {
  name: "allOrders",
  data: () => {
    return {
      data: {
        services: [],
        orders: [],
        sort: "0",
        notValue: true,
      },
      timer: null,
    };
  },
  created: function () {
    this.$f7.dialog.preloader("Пожалуйста подождите");
    // console.log(ApiService.user().working);
    this.getUserServices()
      .then(() => {
        this.$f7.dialog.close();
      })
      .catch(() => {
        this.$f7.dialog.close();
      });
    this.timer = setInterval(this.getUserServicesTimer, 10000);
  },
  methods: {
    getUserServices() {
      return new Promise((resolve) => {
        ApiService.getUserServices()
          .then((response) => {
            this.data.services = response.services;
            this.data.orders = response.orders;
            if (this.data.services.length != 0) this.data.notValue = false;
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    getUserServicesTimer: function () {
      this.getUserServices()
        .then(() => {})
        .catch(() => {});
    },
    smartSelectClose(e) {
      console.log(e);
    },
    onChangeSort() {
      setTimeout(() => {
        this.data.notValue = true;
        this.$refs.messagebar.f7SmartSelect.close();
      }, 200);
    },
    sortOrders(order) {
      if (this.data.sort == 0) {
        this.data.notValue = false;
        return true;
      }
      if (order.service.id == this.data.sort) {
        this.data.notValue = false;
        return true;
      }
      return false;
    },
    servicesAccept(id) {
      console.log("Заказ:", id);
      ApiService.addServicesAccept(id)
        .then((response) => {
          if (response == 1) {
            this.$f7router.navigate("/acceptedOrders/", {
              props: {},
            });
          } else {
            this.$f7.dialog.alert("У вас уже есть заявка", "Ошибка");
          }
        })
        .catch((response) => {
          this.$f7.dialog.alert(response.data.errors, "Ошибка");
        });
    },
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
};
</script>

<style scoped></style>
