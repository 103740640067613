<template>
  <f7-page>
    <f7-block-title v-if="user" style="text-align: center">{{
      user.name
    }}</f7-block-title>
    <div class="content-block">
      <div class="left-menu-profile text-center">
        <div class="row">
          <div class="col-15">
            <a href="#"><i class="flaticon-car"></i></a>
          </div>
          <!-- <div class="col-15">
                       <a href="#"><i class="flaticon-magnifying-glass"></i></a>
                    </div> -->
        </div>
        <!--<div class="margin-top-30">-->
        <!--<img src="http://placehold.it/80x80/?text=USER" alt="user" width="80" height="80">-->
        <!--</div>-->
        <div class="text-small text-capitalize">
          <p class="user-name-menu"></p>
        </div>
        <div class="text-extrat-thiny gray-text text-capitalize icon-location">
          <i class="flaticon-placeholder-1"></i> Владивосток
        </div>
      </div>
    </div>
    <f7-block> </f7-block>
    <f7-list>
      <template v-if="executor">
        <f7-list-item link="/" title="Главная" panel-close></f7-list-item>
        <f7-list-item
          v-if="user != null"
          link="/user-profile/"
          title="Личный кабинет"
          panel-close
        ></f7-list-item>
        <template v-if="user != null">
          <f7-list-item
            v-if="user.status == 2"
            link="/acceptedOrders/"
            title="Принятые заявки"
            panel-close
          ></f7-list-item>
          <f7-list-item
            v-if="user != null && user.status != 2"
            link="/mode-execute/"
            title="Режим исполнителя"
            panel-close
          ></f7-list-item>
        </template>
      </template>
      <template v-else>
        <f7-list-item link="/" title="Главная" panel-close></f7-list-item>
        <f7-list-item
          link="/cameras/"
          title="Карта камер"
          panel-close
        ></f7-list-item>
        <f7-list-item
          v-on:click="redirectItem()"
          href="false"
          link=""
          title="Услуги на год"
          panel-close
        ></f7-list-item>
        <f7-list-item
          v-if="user != null"
          link="/logout/"
          title="Выход"
          panel-close
        ></f7-list-item>
        <f7-list-item link="/faqs/" title="О нас" panel-close></f7-list-item>
        <!--            <f7-list-item link="/calculator/" title="Тарифы" panel-close></f7-list-item>-->
        <f7-list-item link="/blog/" title="Новости" panel-close></f7-list-item>
        <f7-list-item
          v-if="user != null"
          link="/my-orders/"
          title="Мои задачи"
          panel-close
        ></f7-list-item>
        <f7-list-item
          v-if="user == null"
          link="/login/"
          title="Вход"
          panel-close
        ></f7-list-item>
        <f7-list-item
          v-if="user == null"
          link="/register/"
          title="Регистрация"
          panel-close
        ></f7-list-item>
      </template>
    </f7-list>
    <f7-block>
      <f7-button fill round color="green" v-on:click="executorMode()">{{
        returnText()
      }}</f7-button>
      <!--            <div class="coll text-small text-capitalize">-->
      <!--                <i class="flaticon-phone-call"></i>-->
      <!--                <a class="external" href="tel:+7(908)440-49-11">Услуги без-->
      <!--                тарифов</a>-->
      <!--            </div>-->
    </f7-block>
  </f7-page>
</template>
<style scoped>
.coll {
  padding-left: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -30px;
}
</style>
<script>
import ApiService from "../common/api.service";
import { mapState } from "vuex";

// export default {
//     computed: {
//         ...mapState(['somestate']),
//         someComputedLocalState() {
//             // is triggered whenever the store state changes
//             return this.somestate + ' works too';
//         }
//     },
//     watch: {
//         somestate(val, oldVal) {
//             // is triggered whenever the store state changes
//             console.log('do stuff', val, oldVal);
//         }
//     }
// }

export default {
  data: () => {
    return {
      user: ApiService.userLocal(),
      executor: false,
    };
  },
  computed: {
    ...mapState(["isLoginIn"]),
  },
  created: function () {
    // console.log(this.user);
  },
  watch: {
    isLoginIn: function () {
      this.user = ApiService.userLocal()
    },
  },
  methods: {
    logoutModal() {
      this.$f7.dialog.confirm("Действительно выйти?", "Выход", () => {
        /** redirect to router "/logout/" */
        ApiService.logout().then(() => {
          console.debug(ApiService.auth.user);
          this.$f7router.navigate("/", { reloadAll: true });
        });
      });
    },
    redirectItem() {
      window.location = "http://xn-----7kcnecf4ckhbfaeyu6c8d.xn--p1ai/";
    },
    returnText() {
      return this.executor ? "Режим заказчика" : "Режим исполнителя";
    },
    executorMode() {
      this.executor = !this.executor;
    },
    logout() {
      ApiService.logout().then(() => {
        window.location.reload();
      });
    },
  },
};
</script>
