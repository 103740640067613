import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import {API_URL, CLIENT_ID, CLIENT_SECRET} from "./config";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    // Vue.axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
    // Vue.axios.defaults.headers.post['Access-Control-Allow-Origin'] ='*';
    // Vue.axios.defaults.headers.post['Access-Control-Allow-Methods'] ='GET, POST';
    // Vue.axios.defaults.headers.post['Access-Control-Allow-Headers'] ='X-Requested-With';
    console.debug('Authorization ' + this.gatLocalStorage());
  },
  auth: {
    // access_token:null,
    user: null
  },
  login(email, pass) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/oauth/token', {
        grant_type: 'password',
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        username: email,
        password: pass,
        scope: '*'
      }).then(response => {
        console.log(response);
        this.setHeader(response.data.access_token, response.data.token_type);
        this.getUserData().then(responseUser => {
          this.auth.user = responseUser.user;
          this.setLocalStorage(
              responseUser.user,
              response.data.access_token,
              response.data.token_type
          );
          resolve(response);
        }).catch(error => {
          reject(error.response);
        })
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  logout() {
    return new Promise((resolve) => {
      this.auth.user = null;
      this.dellLocalStorage();
      resolve();
      console.log(this.auth.user);
    })
  },
  user() {
    if (this.checkUser() == true) {
      let userNew = new Promise((resolve, reject) => {
        Vue.axios.get('/api/user').then(response => {
          this.setLocalStorageUser(response.data.user);
          resolve(response.data.user);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
      userNew.then(response => {
        this.auth.user = response;
      }).catch((e) => {
        if (e != undefined) {
          if (e.response != undefined) {
            if (e.response.status == 401) {
              this.logout().then(() => {
                window.location.reload();
              });
            }
          }
        }
      });
      return this.auth.user;
    } else
      return false

  },
  userLocal() {
    if (window.localStorage.getItem("user") == null)
      return null;
    else
      return JSON.parse(window.localStorage.getItem("user"));
  },
  checkUser() {
    console.log(window.localStorage.getItem("token"));
    return window.localStorage.getItem("token") == null ? false : true;
  },
  getUserData() {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/api/user').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  gatLocalStorage() {
    let user = JSON.parse(window.localStorage.getItem("user"));
    let token = window.localStorage.getItem("token");
    if (user != null && token != null) {
      this.auth.user = user;
      Vue.axios.defaults.headers.common[
          "Authorization"
          ] = token;
      return true;
    } else {
      return false;
    }
  },
  setLocalStorage(user, access_token, token_type) {
    window.localStorage.setItem("user", JSON.stringify(user));
    window.localStorage.setItem("token", `${token_type} ${access_token}`);
  },
  dellLocalStorage() {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
  },
  setUser(user) {
    this.auth.user = user;
    this.setLocalStorageUser(user);
  },
  setLocalStorageUser(user) {
    window.localStorage.setItem("user", JSON.stringify(user));
  },
  setHeader(access_token, token_type) {
    Vue.axios.defaults.headers.common[
        "Authorization"
        ] = `${token_type} ${access_token}`;
  },
  getIndex() {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/index-page').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  async getService() {
    try {
      const res = await Vue.axios.get('/api/get-service')
      console.log(res);
      return res.data;
    } catch (error) {
      return []
    }
  },
  getUserCash() {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/get-user-cash').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  getUserServices() {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/user-services').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  addCalculateBuyService(data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/calculate-buy', data).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  orderClose(id) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('api/services/cancel', {
        orders_id: id,
        api: true
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  orderSuccess(id) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('api/services/success', {
        orders_id: id,
        api: true
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  updateServices(services) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/update-services', {services: services}).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  addOrder(description, service_id, geolocation) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/add-order', Object.assign({
        data_type_geolocation: 0
      }, {
        description: description,
        service_id: service_id,
        geolocation: geolocation
      })).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  cancelOrder(order_id) {
    return new Promise((resolve, reject) => {
      Vue.axios.post(`/api/e-cancel-order/${order_id}`).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  addServicesAccept(orders_id) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/services-accept', {orders_id: orders_id}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  addBecomeContractor(data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/add-become-contractor', data, {
        headers: {
          'Content-type': 'multipart/form-data;boundary="boundary"',
        }
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  getCity() {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/city').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  getNews() {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/mobile-news').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  getNew(id) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/mobile-news/details', {id: id}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  getAutoMarks() {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/car-make').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  getAutoModels(id) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/api/car-models/' + id).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  getUserPosition(order_id) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/get-user-position', {order_id: order_id}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  submitRegistration(form) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/register', form).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },
  getMyOrders() {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/user-orders').then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  getMessages(id_order) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/dialogs', {order_id: id_order}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  sendMessages(order_id, body, user_to) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/send-dialogs', {order_id: order_id, body: body, user_to: user_to}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  working(status, token) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/working', {working: status, phone_token: token, id: this.auth.user.id}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  userPosition(lat, lon, order_id) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/user-position', {lat: lat, lon: lon, order_id: order_id}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  userPayUrl(summ_plus_cash) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/up-cash-wallet', {"summ-plus-cash": summ_plus_cash, type: 'mobile'}).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
  resetPass(form) {
    return new Promise((resolve, reject) => {
      // console.log('reset');
      Vue.axios.post('/api/reset', form).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.response);
      });
    });
  },
};

export default ApiService;
