<template>
  <f7-page :page-content="false">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">О нас</div>
    </f7-navbar>
    <f7-toolbar tabbar>
      <f7-link tab-link="#tab-1" tab-link-active>О нас</f7-link>
      <f7-link tab-link="#tab-2">Вопрос ответ</f7-link>
    </f7-toolbar>
    <f7-tabs animated>
      <f7-tab id="tab-1" class="page-content" tab-active>
        <f7-block>
          <f7-block-title>О ПРОЕКТЕ TUBE CITY</f7-block-title>
          <f7-block strong tablet-inset>
            <p>
              Tube City сервис по агрегированию городских авто услуг на
              территории России
            </p>
          </f7-block>
          <p>
            Мы - первый сервис-агрегатор на Дальнем-Востоке, предоставляющий
            исполнителей для оказания автоуслуг. Наша главная задача -
            обеспечить водителей недорогими и качественными услугами, которые
            быстро и результативно устраняют неприятности на дороге.
          </p>
          <p>
            «Tube-City» - это мобильное приложение, сайт и горячая линия. Вы
            можете вызвать эвакуатор, техническую и юридическую помощь любым
            способом: создайте заявку одним кликом на смартфоне, позвоните по
            телефону или свяжитесь с нашим оператором по WhatsApp.
          </p>
          <p>
            К вам немедленно будет направлен исполнитель, находящийся
            поблизости. Каждый исполнитель, зарегистрированный в Tube-City, —
            квалифицированный специалист или автосервис, которые профессионально
            выполняют услуги техпомощи на дороге во Владивостоке. В команде
            исполнителей также работают аварийные комиссары, юристы и страховые
            агенты, которые оказывают соответствующие услуги.
          </p>
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-2" class="page-content">
        <f7-block>
          <f7-block-title>Популярные вопросы (FAQ's)</f7-block-title>
          <f7-list accordion-list>
            <f7-list-item
              accordion-item
              title="С чего начать работу на сайте после регистрации?"
            >
              <f7-accordion-content>
                <f7-block>
                  <p>
                    1. Для начала пройдите в свой Профиль, проверьте
                    корректность контактной информации, добавьте информацию по
                    автомобилю в разделе «Личные данные».
                  </p>
                  <p>
                    2. Создайте заявку на городские авто услуги, либо позвоните
                    на горячую линию.
                  </p>
                </f7-block>
              </f7-accordion-content>
            </f7-list-item>
            <f7-list-item
              accordion-item
              title="Как строится диалог с Исполнителями на сайте?"
            >
              <f7-accordion-content>
                <f7-block>
                  <p>
                    Получая ответ на заявку, вы можете перейти в чат и
                    полноценно в режиме online вести общение с Исполнителем.
                  </p>
                </f7-block>
              </f7-accordion-content>
            </f7-list-item>
            <f7-list-item accordion-item title="Могу ли я изменить пароль?">
              <f7-accordion-content>
                <f7-block>
                  <p>На вкладке «Профиль» вы можете задать новый пароль.</p>
                </f7-block>
              </f7-accordion-content>
            </f7-list-item>
            <f7-list-item
              accordion-item
              title="А ваш сайт поддерживается в мобильных устройствах?"
            >
              <f7-accordion-content>
                <f7-block>
                  <p>
                    Наш сайт легко загружается как в мобильном телефоне, так и
                    на планшете, а так же у нас есть мобильное приложение для
                    системы Android и IOS.
                  </p>
                </f7-block>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
export default {
  name: "faqs",
};
</script>

<style scoped>
/*.list .item-title {*/
/*font-size: 5px;*/
/*}*/
</style>