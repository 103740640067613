<template>
  <div>
    <i class="fa fa-bell-slash-o" aria-hidden="true"></i>
    <f7-toggle
      :checked="workingToggle"
      @change="workingToggle = $event.target.checked"
      v-on:change="working"
      color="green"
    ></f7-toggle>
    <i class="fa fa-bell-o" aria-hidden="true"></i>
  </div>
</template>

<script>
import ApiService from "../common/api.service.js";

export default {
  data: () => {
    return {
      workingToggle: null,
    };
  },
  created: function () {
    if (ApiService.user().working == 1) {
      // console.log(ApiService.user().working);
      this.workingToggle = true;
    }
  },
  methods: {
    working(value) {
      if (value.target.checked == false) {
        this.$f7.dialog.preloader("Пожалуйста подождите");
        ApiService.working(2, "")
          .then((res) => {
            ApiService.setUser(res);
            // console.log(ApiService.user());
            this.$f7.dialog.close();
            alert("Режим исполнителя отключен");
          })
          .catch(() => {
            this.$f7.dialog.close();
          });
      } else {
        // window.FirebasePlugin.getToken((token) => {
        this.$f7.dialog.preloader("Пожалуйста подождите");
        ApiService.working(1, "")
          .then((res) => {
            ApiService.setUser(res);
            // console.log(ApiService.user());
            this.$f7.dialog.close();
          })
          .catch(() => {
            this.$f7.dialog.close();
          });
        // }, function (error) {
        //   console.error(error);
        // });
      }
    },
  },
};
</script>
