<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Карта камер</div>
    </f7-navbar>

    <div
      id="yamap_div_1"
      style="width: 100%; height: 100%"
      class="yamapapi"
    ></div>
  </f7-page>
</template>

<script>
/* eslint-disable */

import ymaps2 from "ymaps";
import $ from "jquery";
export default {
  name: "cameras",
  mounted: function () {
    this.$nextTick(function () {
      setTimeout(() => {
        // eslint-disable-next-line no-global-assign
        self = this;
        ymaps2
          .load(
            "https://api-maps.yandex.ru/2.0-stable/?apikey=28e104e6-a8f5-4a5e-a338-1ee0b90821e0&load=package.full%2Cpackage.geoObjects%2Cpackage.geoQuery%2Cpackage.geoXml&lang=ru-RU&ver=3.8.1"
          )
          .then(() => {
            // eslint-disable-next-line no-undef
            console.log(ymaps);
            var div = this.$el.querySelector("#yamap_div_1");
            // eslint-disable-next-line no-undef
            var l_YaMap1 = new ymaps.Map(
              div,
              {
                center: [43.120942, 131.936073],
                type: "yandex#map",
                zoom: 12,
              },
              { maxZoom: 16, minZoom: 10 }
            );
            l_YaMap1.controls.add("zoomControl", { left: "10px" });
            // eslint-disable-next-line no-undef
            l_YaMap1.controls.add(new ymaps.control.TypeSelector());
            l_YaMap1.controls.add("mapTools", { left: "40px" });
            l_YaMap1.controls.add("scaleLine");
            // eslint-disable-next-line no-undef
            l_YaMap1.controls.add(
              new ymaps.control.TrafficControl({ shown: false })
            );
            var l_collection = new ymaps.GeoObjectCollection();
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.113502, 131.915632],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a  class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-19ebf17cae96461c11fdce9127b90384&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/svetlanskaya-133-avangard/?city=v'>Светланская 133 - Авангард </a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/svetlanskaya-133-avangard.png?t=2018051612' /><br/>Перекресток на Авангарде"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-19ebf17cae96461c11fdce9127b90384&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/svetlanskaya-133-avangard/?city=v'>Светланская 133 - Авангард </a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/svetlanskaya-133-avangard.png?t=2018051612' /><br/>Перекресток на Авангарде"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.159626, 131.949768],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-7e48237f8c133c25345ae8fe77e91939&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/anny-shhetininoj-3/?city=v'>Анны Щетининой 3</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/anny-shhetininoj-3.png?t=2018051612' /><br/>Перекресток Анны Щетининой - Выселковая"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-7e48237f8c133c25345ae8fe77e91939&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/anny-shhetininoj-3/?city=v'>Анны Щетининой 3</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/anny-shhetininoj-3.png?t=2018051612' /><br/>Перекресток Анны Щетининой - Выселковая"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.103939, 131.94631],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-956ca2fa865d6ab4476a0bd96e4e67cc&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/fadeeva-14/?city=v'>Фадеева 14</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/fadeeva-14.png?t=2018051612' /><br/>Перекрёсток  ул.Фадеева с ул. Воропаева"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-956ca2fa865d6ab4476a0bd96e4e67cc&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/fadeeva-14/?city=v'>Фадеева 14</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/fadeeva-14.png?t=2018051612' /><br/>Перекрёсток  ул.Фадеева с ул. Воропаева"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.133118, 131.898762],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-5be66d9fa6279703766aa74a6325f7b3&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/okeanskij-prospekt-111/?city=v'>Океанский проспект 111</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/okeanskij-prospekt-111.png?t=2018051612' /><br/>Кольцо Первой Речки"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-5be66d9fa6279703766aa74a6325f7b3&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/okeanskij-prospekt-111/?city=v'>Океанский проспект 111</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/okeanskij-prospekt-111.png?t=2018051612' /><br/>Кольцо Первой Речки"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.128123, 131.900203],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-61b8df285e098f0824f0c45727f22ce2&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/kolco-instrumentalnogo-zavoda/?city=v'>Кольцо Инструментального завода</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/kolco-instrumentalnogo-zavoda.png?t=2018051612' /><br/>Инструментальный завод"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-61b8df285e098f0824f0c45727f22ce2&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/kolco-instrumentalnogo-zavoda/?city=v'>Кольцо Инструментального завода</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/kolco-instrumentalnogo-zavoda.png?t=2018051612' /><br/>Инструментальный завод"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.112671, 131.879199],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-42b2cb7e6b967f2c4864155d7bb1abb5&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/morskaya-1-ya-625/?city=v'>Морская 1-я 6/25</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/morskaya-1-ya-625.png?t=2018051612' /><br/>Морская 1-я 6/25"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-42b2cb7e6b967f2c4864155d7bb1abb5&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/morskaya-1-ya-625/?city=v'>Морская 1-я 6/25</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/morskaya-1-ya-625.png?t=2018051612' /><br/>Морская 1-я 6/25"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.105896, 131.874514],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-7e762b70003632cf264f039b138ba8c7&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/bestuzheva-5/?city=v'>Бестужева 5</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/bestuzheva-5.png?t=2018051612' /><br/>Бестужева"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-7e762b70003632cf264f039b138ba8c7&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/bestuzheva-5/?city=v'>Бестужева 5</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/bestuzheva-5.png?t=2018051612' /><br/>Бестужева"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.095982, 131.861814],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-99e44525efa8424256000a72b5de0aba&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/kazanskij-most/?city=v'>Казанский мост</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/kazanskij-most.png?t=2018051612' /><br/>Казанский мост, по направлению в центр города"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-99e44525efa8424256000a72b5de0aba&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/kazanskij-most/?city=v'>Казанский мост</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/kazanskij-most.png?t=2018051612' /><br/>Казанский мост, по направлению в центр города"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.091838, 131.918171],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-zlq7dzNTLYUATQoAxZ3gza&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/kiparisovaya-20/?city=v'>Кипарисовая 20</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/kiparisovaya-20.png?t=2018051612' /><br/>Окатовая"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-zlq7dzNTLYUATQoAxZ3gza&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/kiparisovaya-20/?city=v'>Кипарисовая 20</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/kiparisovaya-20.png?t=2018051612' /><br/>Окатовая"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.101053, 131.943164],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-bcb491d239cb738ffb27adbbf7e55937&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/borisenko-16/?city=v'>Борисенко 16</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/borisenko-16.png?t=2018051612' /><br/>Школьная"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-bcb491d239cb738ffb27adbbf7e55937&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/borisenko-16/?city=v'>Борисенко 16</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/borisenko-16.png?t=2018051612' /><br/>Школьная"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.133097, 131.94742],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-a7bb59dd3f0657977e62ede90539ad70&camera=0&width=1920&height=1072&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/lugovaya-68/?city=v'>Луговая, 68</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/lugovaya-68.png?t=2018051612' /><br/>Рудневский мост"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-a7bb59dd3f0657977e62ede90539ad70&camera=0&width=1920&height=1072&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/lugovaya-68/?city=v'>Луговая, 68</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/lugovaya-68.png?t=2018051612' /><br/>Рудневский мост"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.104516, 131.936825],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-1Rrqh4mFxLvfxSyqoeCMuh&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/sportivnaya-5/?city=v'>Спортивная, 5</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/sportivnaya-5.png?t=2018051612' /><br/>Кольцо Спортивная - Калинина"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-1Rrqh4mFxLvfxSyqoeCMuh&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/sportivnaya-5/?city=v'>Спортивная, 5</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/sportivnaya-5.png?t=2018051612' /><br/>Кольцо Спортивная - Калинина"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.167618, 131.916108],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-pWd3Mtp1ErIEkLqb77E9eb&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw='>Русская, 48</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/russkaya-48.png?t=2018051612' /><br/>Развязка Русская - 100 лет Владивостоку"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-pWd3Mtp1ErIEkLqb77E9eb&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw='>Русская, 48</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/russkaya-48.png?t=2018051612' /><br/>Развязка Русская - 100 лет Владивостоку"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.125856, 131.911001],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-zZKtIF4EFzhNfwRgNXV8cs&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/razvyazka-na-gogolya/?city=v'>Развязка на Гоголя</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/razvyazka-na-gogolya.png?t=2018051612' /><br/>Вид с '1000 мелочей' / 'Пять +'"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-zZKtIF4EFzhNfwRgNXV8cs&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/razvyazka-na-gogolya/?city=v'>Развязка на Гоголя</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/razvyazka-na-gogolya.png?t=2018051612' /><br/>Вид с '1000 мелочей' / 'Пять +'"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.129439, 131.911988],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-eVBYM0Nq2IbeQcCekhOeeZ&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/nekrasovskaya-90/?city=v'>Некрасовская, 90</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/nekrasovskaya-90.png?t=2018051612' /><br/>Вид на путепровод"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-eVBYM0Nq2IbeQcCekhOeeZ&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/nekrasovskaya-90/?city=v'>Некрасовская, 90</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/nekrasovskaya-90.png?t=2018051612' /><br/>Вид на путепровод"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.176633, 131.917717],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-ff029053b7428262d54466545af023ba&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw='>100 лет Владивостоку проспект, 145а</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/100-let-vl-ku-145a.png?t=2018051612' /><br/>Фабрика 'Заря', выезд из города"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='' class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-ff029053b7428262d54466545af023ba&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=>100 лет Владивостоку проспект, 145а</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/100-let-vl-ku-145a.png?t=2018051612' /><br/>Фабрика 'Заря', выезд из города"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.169283, 131.931268],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-05be47c8c926cb54a9593e739f373c00&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/russkaya-55a/?city=v'>Русская, 55а</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/russkaya-55a.png?t=2018051612' /><br/>Вид на кольцо Багратиона"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-05be47c8c926cb54a9593e739f373c00&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/russkaya-55a/?city=v'>Русская, 55а</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/russkaya-55a.png?t=2018051612' /><br/>Вид на кольцо Багратиона"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: { type: "Point", coordinates: [43.1196, 131.884307] },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-za8EdcQgfRz7Emf9ce0Utm&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/mordovceva-3/?city=v'>Мордовцева, 3</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/mordovceva-3.png?t=2018051612' /><br/>Площадь Семеновская"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-za8EdcQgfRz7Emf9ce0Utm&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/mordovceva-3/?city=v'>Мордовцева, 3</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/mordovceva-3.png?t=2018051612' /><br/>Площадь Семеновская"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.11769, 131.936482],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-78416dd0919e8f8761de20de69bab4cf&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/lugovaya-45/?city=v'>Луговая, 45/2</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/lugovaya-45.png?t=2018051612' /><br/>Поворот на озера, пересечение улиц Луговая и Карская"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-78416dd0919e8f8761de20de69bab4cf&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/lugovaya-45/?city=v'>Луговая, 45/2</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/lugovaya-45.png?t=2018051612' /><br/>Поворот на озера, пересечение улиц Луговая и Карская"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.121753, 131.927234],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-NAbiKvec9OiLOebkWM5hQc&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/krasnogo-znameni-114/?city=v'>Красного Знамени, 114</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/krasnogo-znameni-114.png?t=2018051612' /><br/>Кольцо 3-ей Рабочей"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-NAbiKvec9OiLOebkWM5hQc&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/krasnogo-znameni-114/?city=v'>Красного Знамени, 114</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/krasnogo-znameni-114.png?t=2018051612' /><br/>Кольцо 3-ей Рабочей"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.100955, 131.893137],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-88QTJdHdYh0SMFStxFyBSG&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/kalinina-84/?city=v'>Калинина, 84</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/kalinina-84.png?t=2018051612' /><br/>Вид на Золотой мост со стороны м. Чуркин"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-88QTJdHdYh0SMFStxFyBSG&camera=0&width=1920&height=1080&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/kalinina-84/?city=v'>Калинина, 84</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/kalinina-84.png?t=2018051612' /><br/>Вид на Золотой мост со стороны м. Чуркин"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.112055, 131.932062],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-5698dbc91ef8edf7be6a44e8bda18d56&camera=0&width=1280&height=1024&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/ivanovskaya-17/?city=v'>Ивановская, 17</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/ivanovskaya-17.png?t=2018051612' /><br/>Площадь Луговая в сторону ул. Спортивная"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-5698dbc91ef8edf7be6a44e8bda18d56&camera=0&width=1280&height=1024&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/ivanovskaya-17/?city=v'>Ивановская, 17</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/ivanovskaya-17.png?t=2018051612' /><br/>Площадь Луговая в сторону ул. Спортивная"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.128622, 131.89333],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-e99adc0daf52437b89df8b57a34a2b7c&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/dalpress/?city=v'>остановка 'Дальпресс'</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/dalpress.png?t=2018051612' /><br/>Остановка транспорта 'Дальпресс'"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-e99adc0daf52437b89df8b57a34a2b7c&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/dalpress/?city=v'>остановка 'Дальпресс'</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/dalpress.png?t=2018051612' /><br/>Остановка транспорта 'Дальпресс'"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.12891, 131.938567],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-99eb710f0a6954e02086b37111369164&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/balyaeva-56/?city=v'>Баляева, 56</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/balyaeva-56.png?t=2018051612' /><br/>Вид на Баляевский мост"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-99eb710f0a6954e02086b37111369164&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/balyaeva-56/?city=v'>Баляева, 56</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/balyaeva-56.png?t=2018051612' /><br/>Вид на Баляевский мост"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.152015, 131.908646],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-7994fa9605c860c4bfc4f7e4f93981f9&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/iskra-za-gorod/?city=v'>ТЦ 'Искра' (100 лет Владивостоку, 42а) №1</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/iskra-za-gorod.png?t=2018051612' /><br/>ТЦ 'Искра', по направлению из города"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-7994fa9605c860c4bfc4f7e4f93981f9&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/iskra-za-gorod/?city=v'>ТЦ 'Искра' (100 лет Владивостоку, 42а) №1</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/iskra-za-gorod.png?t=2018051612' /><br/>ТЦ 'Искра', по направлению из города"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            var l_GeoObject = new ymaps.GeoObject(
              {
                geometry: {
                  type: "Point",
                  coordinates: [43.152212, 131.908099],
                },
                properties: {},
              },
              { geodesic: true, draggable: false }
            );
            l_GeoObject.properties.set(
              "balloonContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-dc78c7528cbc81dd902aedf8a2953f46&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/iskra-v-centr/?city=v'>ТЦ 'Искра' (100 лет Владивостоку, 42а) №2</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/iskra-v-centr.png?t=2018051612' /><br/>ТЦ 'Искра', по направлению в центр города"
            );
            l_GeoObject.properties.set(
              "hintContent",
              "<a class='camera-details' data-url='http://glaz.inetvl.ru/embed/v2/?server=100-dc78c7528cbc81dd902aedf8a2953f46&camera=0&width=1280&height=720&lang=ru&ap=&fs=&noibw=' href='http://inetvl.ru/glaz/cameras/iskra-v-centr/?city=v'>ТЦ 'Искра' (100 лет Владивостоку, 42а) №2</a><br/><img width='250' src='//inetvl.ru/static/images/maps_preview/iskra-v-centr.png?t=2018051612' /><br/>ТЦ 'Искра', по направлению в центр города"
            );
            l_GeoObject.options.set("strokeWidth", "2");
            l_GeoObject.options.set("borderRadius", "0");
            l_collection.add(l_GeoObject);
            if (l_collection.getLength() > 0) {
              l_YaMap1.geoObjects.add(l_collection);
            }
            $("body").on("click", ".camera-details", function () {
              self.$f7router.navigate("/camera-details/", {
                props: {
                  video: $(this).data().url,
                },
              });
              console.log($(this).data());
            });
          })
          .catch((error) => console.log("Failed to load Yandex Maps", error));
      }, 200);
    });
  },
};
</script>

<style scoped>
#yamap_div_1 {
  /* Must bigger size than 100x100 pixels */
  width: 100%;
  height: 100%;
}
</style>