<template>
  <f7-tab class="page-content">
    <f7-block-title>Принятые заявки</f7-block-title>
    <f7-block v-if="order.execute != null">
      <div class="content-block">
        <div class="card order">
          <div class="card-header">
            <div class="order-categories">
              Категория: {{ order.execute.service.name }}
            </div>
            <!--                                <div class="order-fio">Гос номер: {{ order.execute.user.state_number }}</div>-->
          </div>
          <div class="card-content card-content-padding">
            <h5>Коментарий</h5>
            <p v-html="order.execute.description"></p>
            <p class="likes">
              Телефон:
              <a
                v-if="
                  typeof order.execute.data.phone != undefined &&
                  order.execute.data.phone !== null
                "
                class="external"
                :href="'tel:' + order.execute.data.phone"
              >
                {{ order.execute.data.phone }}</a
              >
              <a
                v-else
                class="external"
                :href="'tel:' + order.execute.user.phone"
              >
                {{ order.execute.user.phone }}</a
              >
            </p>
            <p class="likes">
              Адрес:
              <a
                class="external"
                :href="
                  'geo:0,0?q=Владивосток,+' +
                  encodeURI(order.execute.data.geolocation)
                "
                >{{ order.execute.data.geolocation }}</a
              >
            </p>
          </div>
          <div class="card-footer">
            <a
              style="color: orange"
              v-on:click="cancelOrder(order.execute.id)"
              class="link"
              >Отменить задачу</a
            >
            <!--                                <a v-on:click="chatPage(order.execute.id)" href="/messages/?order_id=execute.id&user_id=execute.executor_id" class="link" style="margin-left: 10%;">Чат</a>-->
          </div>
          <div class="card-footer">
            <a
              style="color: green"
              v-on:click="orderSuccess(order.execute.id)"
              class="link"
              >Завершит задачу</a
            >
          </div>
        </div>
        <p v-if="order.execute == null" style="margin-left: 16px">
          У вас пока нет заявок
        </p>
      </div>
    </f7-block>
  </f7-tab>
</template>

<script>
import ApiService from "../common/api.service";

export default {
  props: ["navigate", "address", "services", "description", "tabId"],
  data: () => {
    return {
      order: {
        services: [],
        execute: null,
      },
      orderAdd: {
        services: null,
        description: "",
        position: "",
      },
      index: "tab-index-1",
      redy: false,
      intervalPosition: null,
    };
  },
  created: function () {
    this.orderAdd.position = this.address;
    this.orderAdd.services = this.services;
    this.orderAdd.description = this.description;
    ApiService.user();
    // console.log(this.order);
    this.getIndex();
    if (this.$isMobile()) {
      console.log(this.$isMobile());
      this.sendMsgToFlutter();
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalPosition);
  },
  methods: {
    sendMsgToFlutter() {
      let user = ApiService.user();
      if (user != undefined) {
        // console.log(user.data.user);
        window.Toaster.postMessage(user.id);
      }
    },
    log(data) {
      console.log(data);
    },
    addOrder: function () {
      ApiService.addOrder(
        this.orderAdd.description,
        this.orderAdd.services,
        this.orderAdd.position
      )
        .then((response) => {
          this.$f7router.navigate("/my-orders/");
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelOrder(id) {
      ApiService.cancelOrder(id)
        .then((response) => {
          if (response == 1) {
            this.$f7.dialog.alert(
              "Заявка успешно отменена",
              "Уведомления",
              () => {
                this.$f7router.navigate("/", {
                  props: {
                    navigate: "#tab-index-2",
                  },
                });
              }
            );
          }
        })
        .catch(() => {});
    },
    orderSuccess(id) {
      ApiService.orderSuccess(id)
        .then((response) => {
          if (response == 1) {
            this.$f7.dialog.confirm("Заявка выполенна. Перейти на главную?", "Уведомления", () => {
              this.$f7router.navigate("/", {
                props: {
                  navigate: "#tab-index-2",
                },
              });
            });
          }
        })
        .catch(() => {});
    },
    getIndex() {
      this.$f7.dialog.preloader("Пожалуйста подождите");
      ApiService.getIndex()
        .then(async (response) => {
          this.order.services = await ApiService.getService();
          this.order.execute = response.execute;
          this.$f7.dialog.close();
        })
        .catch(() => {
          this.$f7.dialog.close();
        });
    },
    chatPage(id) {
      this.$f7router.navigate("/chat/", {
        props: {
          navigate: id,
        },
      });
    },
    selectMap() {
      console.log("select-map");
      this.$f7router.navigate("/select-map/", {
        props: {
          services: this.orderAdd.services,
          description: this.orderAdd.description,
        },
      });
    },
  },
};
</script>
