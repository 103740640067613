<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Все заказы</div>
      <f7-nav-right>
        <i class="fa fa-bell-slash-o" aria-hidden="true"></i>
        <f7-toggle
          :checked="workingToggle"
          @change="workingToggle = $event.target.checked"
          v-on:change="working"
          color="green"
        ></f7-toggle>
        <i class="fa fa-bell-o" aria-hidden="true"></i>
        <!-- <f7-link
          smart-select
          ref="messagebar"
          :smart-select-params="{
            sheetCloseLinkText: 'Закрыть',
            openIn: 'sheet',
            smartSelectClose: smartSelectClose,
          }"
          icon="fa fa-filter"
        >
          <select
            @change="onChangeSort()"
            v-model="data.sort"
            name="mac-windows"
          >
            <option :value="0" selected>Все категории</option>
            <option
              v-for="(service, serviceKey) in data.services"
              :value="service.id"
              :key="serviceKey"
            >
              {{ service.name }}
            </option>
          </select>
        </f7-link> -->
      </f7-nav-right>
    </f7-navbar>
    <template v-if="sortOrders(data.orders)">
      <f7-card v-bind:key="orderKey" v-for="(order, orderKey) in data.orders">
        <f7-card-header>
          <span style="font-size: 12px"
            >Дата {{ order.created_at | moment("DD.MM | HH:mm") }}</span
          >
        </f7-card-header>
        <f7-card-content :padding="false">
          <f7-list medial-list>
            <f7-list-item
              header="Адрес"
              :title="order.data.geolocation"
              subtitle="Beatles"
            >
              <f7-icon slot="media" icon="fa fa-map-marker"></f7-icon>
            </f7-list-item>
            <f7-list-item
              header="Категория"
              :title="order.service.name"
              subtitle="Beatles"
            >
              <f7-icon slot="media" icon="fa fa-th"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-card-content>
        <f7-card-content v-html="order.description"> </f7-card-content>
        <f7-card-footer>
          <template v-if="order.status == 1">
            <f7-link v-on:click="servicesAccept(order.id)">Принять</f7-link>
          </template>
          <template v-if="order.status == 3">
            <h5>Заявка выполнена</h5>
          </template>
        </f7-card-footer>
      </f7-card>
    </template>
    <f7-block-title v-if="data.notValue"
      >Нет заявок по категории</f7-block-title
    >
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";

export default {
  name: "allOrders",
  data: () => {
    return {
      data: {
        services: [],
        orders: [],
        sort: "0",
        notValue: true,
      },
      timer: null,
      workingToggle: null,
    };
  },
  created: function () {
    this.$f7.dialog.preloader("Пожалуйста подождите");
    // console.log(ApiService.user().working);
    if (ApiService.user().working == 1) {
      // console.log(ApiService.user().working);
      this.workingToggle = true;
    }
    this.getUserServices()
      .then(() => {
        this.$f7.dialog.close();
      })
      .catch(() => {
        this.$f7.dialog.close();
      });
    this.timer = setInterval(this.getUserServicesTimer, 10000);
  },
  methods: {
    getUserServices() {
      return new Promise((resolve) => {
        ApiService.getUserServices()
          .then((response) => {
            this.data.services = response.services;
            this.data.orders = response.orders;
            if (this.data.services.length != 0) this.data.notValue = false;
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    getUserServicesTimer: function () {
      this.getUserServices()
        .then(() => {})
        .catch(() => {});
    },
    smartSelectClose(e) {
      console.log(e);
    },
    onChangeSort() {
      setTimeout(() => {
        this.data.notValue = true;
        this.$refs.messagebar.f7SmartSelect.close();
      }, 200);
    },
    sortOrders(order) {
      if (this.data.sort == 0) {
        this.data.notValue = false;
        return true;
      }
      if (order.service.id == this.data.sort) {
        this.data.notValue = false;
        return true;
      }
      return false;
    },
    servicesAccept(id) {
      console.log(id);
      ApiService.addServicesAccept(id).then((response) => {
        if (response == 1) {
          this.$f7router.navigate("/", {
            props: {
              navigate: "tab-index-2",
            },
          });
        } else {
          this.$f7.dialog.alert("У вас уже есть заявка", "Ошибка");
        }
      });
    },
    working(value) {
      if (value.target.checked == false) {
        this.$f7.dialog.preloader("Пожалуйста подождите");
        ApiService.working(2, "")
          .then((res) => {
            ApiService.setUser(res);
            // console.log(ApiService.user());
            this.$f7.dialog.close();
            alert("Режим исполнителя отключен");
          })
          .catch(() => {
            this.$f7.dialog.close();
          });
      } else {
        // window.FirebasePlugin.getToken((token) => {
        this.$f7.dialog.preloader("Пожалуйста подождите");
        ApiService.working(1, "")
          .then((res) => {
            ApiService.setUser(res);
            // console.log(ApiService.user());
            this.$f7.dialog.close();
          })
          .catch(() => {
            this.$f7.dialog.close();
          });
        // }, function (error) {
        //   console.error(error);
        // });
      }
    },
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
</style>
