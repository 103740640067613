<template>
  <f7-page>
    <f7-navbar title="Отслеживания" back-link="Back" sliding></f7-navbar>
    <div id="map-executor" style="width: 100%; height: 100%"></div>
  </f7-page>
</template>

<script>
import ymaps2 from "ymaps";
import ApiService from "../common/api.service";

export default {
  name: "mapExecutor",
  props: ["id"],
  data: () => {
    return {
      timer: null,
      myMap: null,
      myPlacemark: null,
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      setTimeout(() => {
        ymaps2
          .load(
            "https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=28e104e6-a8f5-4a5e-a338-1ee0b90821e0"
          )
          // eslint-disable-next-line no-unused-vars
          .then((maps) => {
            // eslint-disable-next-line no-undef
            console.log(ymaps);
            var div = this.$el.querySelector("#map-executor");
            ApiService.getUserPosition(this.id)
              .then((response) => {
                // eslint-disable-next-line no-undef
                this.myMap = new ymaps.Map(div, {
                  center: [response.x_executor, response.y_executor],
                  zoom: 10,
                });
              })
              .catch(() => {});
            // eslint-disable-next-line no-undef
            this.myPlacemark = new ymaps.Placemark();
            this.timer = setInterval(this.getUserPosition, 4000);
          })
          .catch((error) => console.log("Failed to load Yandex Maps", error));
      }, 200);
    });
  },
  destroyed: function () {
    clearInterval(this.timer);
    console.log("destroyed");
  },
  methods: {
    getUserPosition: function () {
      ApiService.getUserPosition(this.id)
        .then((response) => {
          let array = [+response.x_executor, +response.y_executor];
          console.log(array);
          this.myPlacemark.geometry.setCoordinates(array); // устанавливаем метке координаты, высчитанные из положения клика
          this.myMap.geoObjects.add(this.myPlacemark); // ставим метку на карту
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>
