<template>
  <f7-page>
    <div class="page-content" id="app-cover">
      <div class="pull-left">
        <a class="panel-open main-menu" href="#"
          ><i class="icon icon-bars"></i
        ></a>
      </div>
      <div class="clearfix"></div>
      <div class="app-cover-content">
        <div class="margin-bottom-10">
          <img
            src="../../img/tublogo.png"
            alt="Carlitos"
            width="100"
            height="100"
          />
        </div>
        <div class="text-large margin-bottom-15">
          Подписка на тарифы «Tube-City» — это:
        </div>
        <div class="margin-bottom-20" style="text-align: left">
          <p>* цены на автоуслуги со скидкой до 70% от рыночной,</p>
          <p>
            * конструктор, который позволяет сформировать пакет только
            актуальных для вас услуг,
          </p>
          <p>* гарантированная помощь, даже если нет с собой наличных,</p>
          <p>
            Подпишитесь сейчас и будьте спокойны — в случае необходимости помощь
            придет!
          </p>
        </div>
        <div class="row">
          <div class="col-50">
            <f7-button
              v-on:click="login()"
              fill
              class="color-green"
              style="background: #0aaaa0 !important; color: #fff !important"
              >Вход</f7-button
            >
          </div>
          <div class="col-50">
            <f7-button v-on:click="register()" fill class="color-green"
              >Регистрация</f7-button
            >
          </div>
        </div>
        <div class="row offset-top-10 margin-bottom-10">
          <div class="col-100">
            <f7-button
              v-on:click="back()"
              fill
              class="color-green"
              style="background: #2196f3 !important; color: #fff"
              >Назад</f7-button
            >
          </div>
        </div>
        <div class="row offset-top-10 margin-bottom-10">
          <div class="col-100">
            <a
              href="tel:+7(908)440-49-11"
              class="
                external
                button button-fill
                color-blue
                white-text
                text-thiny
              "
              style="background: #e96c4c !important"
            >
              Заказать сейчас
            </a>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
export default {
  name: "index_tutorial_2",
  methods: {
    login() {
      this.$f7router.navigate("/login/");
    },
    register() {
      this.$f7router.navigate("/register/");
    },
    back() {
      this.$f7router.back();
    },
  },
};
</script>

<style scoped>
</style>