<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Тарифы</div>
    </f7-navbar>
    <f7-block strong>
      <div class="content-block">
        <div class="content-block-inner content-background">
          <p class="text-thiny">
            Для того, чтобы пользоваться услугам данного сервиса, вы можете
            приобрести готовый пакет. Или собрать пакет услуг самостоятельно с
            помощью калькулятора.
          </p>
        </div>
      </div>
      <div class="content-block text-center">
        <div class="row">
          <div class="price-teb">
            <div class="card col-100">
              <div class="card-header">
                <h1 style="color: #e96c4c">Основной</h1>
              </div>
              <div class="card-content card-content-padding">
                <h3>549</h3>
                <p class="text-thiny">
                  Основной тариф - включает в себя все необходимые услуги,
                  которые помогут в городской среде не думать о проблемах,
                  связанных с авто. Наши специалисты быстро реагируют на все
                  заявки. Тариф включает в себя:
                </p>
                <h5>При подключении</h5>
                <p data-popover=".popover-mounting" class="popover-open">
                  Шиномонтаж x1
                </p>
                <p data-popover=".popover-komisar" class="popover-open">
                  Аварийный комиссар x1
                </p>
                <p data-popover=".popover-emergency-help" class="popover-open">
                  Экстренная помошь x1
                </p>
              </div>
              <div class="card-footer">
                <button
                  data-id="1"
                  style="background: #e96c4c; color: white"
                  class="button col click-buy-package"
                >
                  Подключиться
                </button>
              </div>
            </div>
            <div class="card col-100">
              <div class="card-header">
                <h1 style="color: #0aaaa0">Стандарт</h1>
              </div>
              <div class="card-content card-content-padding">
                <h3>1399</h3>
                <p class="text-thiny">
                  Один из самых популярных тарифов на сайте. Тариф очень
                  популярен, поскольку содержит обширный перечень услуг, самой
                  привлекательной из которых является эвакуатор за 1399 руб.
                  Тариф включает в себя:
                </p>
                <h5>При подключении</h5>
                <p data-popover=".popover-evacuator" class="popover-open">
                  Эвакуатор x1
                </p>
                <p data-popover=".popover-mounting" class="popover-open">
                  Шиномонтаж x1
                </p>
                <p data-popover=".popover-komisar" class="popover-open">
                  Аварийный комиссар x1
                </p>
                <p data-popover=".popover-emergency-help" class="popover-open">
                  Экстренная помошь x1
                </p>
              </div>
              <div class="card-footer">
                <button
                  data-id="2"
                  style="background: #0aaaa0; color: white"
                  class="button col click-buy-package"
                >
                  Подключиться
                </button>
              </div>
            </div>
            <div class="card col-100">
              <div class="card-header">
                <h1 style="color: #e96c4c">Расширенный</h1>
              </div>
              <div class="card-content card-content-padding">
                <h3>1899</h3>
                <p class="text-thiny">
                  Тариф Расширенный для людей, которые 90% своей жизни проводят
                  в авто и любят получать только лучшее, по максимально выгодной
                  цене, а также контролировать все услуги в одном месте. Тариф
                  включает в себя:
                </p>
                <h5>При подключении</h5>
                <p data-popover=".popover-evacuator" class="popover-open">
                  Эвакуатор x1
                </p>
                <p data-popover=".popover-mounting" class="popover-open">
                  Шиномонтаж x1
                </p>
                <p data-popover=".popover-komisar" class="popover-open">
                  Аварийный комиссар x1
                </p>
                <p data-popover=".popover-emergency-help" class="popover-open">
                  Экстренная помошь x1
                </p>
                <p data-popover=".popover-legal" class="popover-open">
                  Вопрос юристу x1
                </p>
                <p data-popover=".popover-insurance" class="popover-open">
                  Страховка x1
                </p>
                <p data-popover=".popover-diagnostica" class="popover-open">
                  Выездная диагностика x1
                </p>
              </div>
              <div class="card-footer">
                <button
                  data-id="3"
                  style="background: #e96c4c; color: white"
                  class="button col click-buy-package"
                >
                  Подключиться
                </button>
              </div>
            </div>
          </div>
        </div>
        <form class="calculator">
          <div class="row">
            <h3 style="margin: 10px 0px; font-weight: 100">
              Калькулятор Tube City
            </h3>
            <div class="col-50">
              <div href="used-car-parts.html" class="car-category">
                <img
                  data-popover=".popover-emergency-help"
                  class="popover-open"
                  src="../../img/icon/2.png"
                  alt=""
                />
                <div class="color-deeporange text-thiny">ЭКСТРЕННАЯ ПОМОЩЬ</div>
                <div class="popover popover-emergency-help">
                  <div class="popover-inner">
                    <div class="block">
                      <p>Прикурить авто, подвоз топлива</p>
                    </div>
                  </div>
                </div>
                <div class="text-extrat-thiny gray-text">
                  <!-- 100+ Car Accessories in your area -->
                  <h4 class="row">
                    <a
                      href="#"
                      v-on:click="greet($event, 2, 'add')"
                      class="add-qty col-40"
                      ><i class="fa fa-plus"></i
                    ></a>
                    <input
                      v-model="
                        calculator.find((value) => value.key === 2).value
                      "
                      data-sum="320"
                      name="ekz"
                      min="0"
                      type="text"
                      value="1"
                      class="qty"
                    />
                    <a
                      href="#"
                      v-on:click="greet($event, 2, 'reduce')"
                      class="substract-qty col-40"
                      ><i class="fa fa-minus"></i
                    ></a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-50">
              <div href="used-car-parts.html" class="car-category">
                <img
                  data-popover=".popover-legal"
                  class="popover-open"
                  src="../../img/icon/3.png"
                  alt=""
                />
                <div class="color-deeporange text-thiny">ВОПРОС ЮРИСТУ</div>
                <div class="popover popover-legal">
                  <div class="popover-inner">
                    <div class="block">
                      <p>
                        Консультация 10 мин. по ПДД, при задержки ДПС,(выезд на
                        место за доп оплату ).
                      </p>
                    </div>
                  </div>
                </div>
                <div class="text-extrat-thiny gray-text">
                  <!-- 100+ Car Accessories in your area -->
                  <h4 class="row">
                    <a
                      href="#"
                      v-on:click="greet($event, 3, 'add')"
                      class="add-qty col-40"
                      ><i class="fa fa-plus"></i
                    ></a>
                    <input
                      v-model="
                        calculator.find((value) => value.key === 3).value
                      "
                      data-sum="180"
                      name="yur"
                      min="0"
                      type="text"
                      value="1"
                      class="qty"
                    />
                    <a
                      href="#"
                      v-on:click="greet($event, 3, 'reduce')"
                      class="substract-qty col-40"
                      ><i class="fa fa-minus"></i
                    ></a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-50">
              <div href="used-car-parts.html" class="car-category">
                <img
                  data-popover=".popover-insurance"
                  class="popover-open"
                  src="../../img/icon/4.png"
                  alt=""
                />
                <div class="color-deeporange text-thiny">СТРАХОВКА</div>
                <div class="popover popover-insurance">
                  <div class="popover-inner">
                    <div class="block">
                      <p>
                        Выезд на место для оформления страховки, купли-продажи,
                        диагностики.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="text-extrat-thiny gray-text">
                  <!-- 100+ Car Accessories in your area -->
                  <h4 class="row">
                    <a
                      href="#"
                      v-on:click="greet($event, 4, 'add')"
                      class="add-qty col-40"
                      ><i class="fa fa-plus"></i
                    ></a>
                    <input
                      v-model="
                        calculator.find((value) => value.key === 4).value
                      "
                      data-sum="90"
                      name="save"
                      min="0"
                      type="text"
                      value="1"
                      class="qty"
                    />
                    <a
                      href="#"
                      v-on:click="greet($event, 4, 'reduce')"
                      class="substract-qty col-40"
                      ><i class="fa fa-minus"></i
                    ></a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-50">
              <div href="used-car-parts.html" class="car-category">
                <img
                  data-popover=".popover-mounting"
                  class="popover-open"
                  src="../../img/icon/5.png"
                  alt=""
                />
                <div class="color-deeporange text-thiny">ШИНОМОНТАЖ</div>
                <div class="popover popover-mounting">
                  <div class="popover-inner">
                    <div class="block">
                      <p>
                        Ремонт прокола колеса на месте, замена запасного колеса
                        или подкачка.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="text-extrat-thiny gray-text">
                  <!--100+ Car Accessories in your area -->
                  <h4 class="row">
                    <a
                      href="#"
                      v-on:click="greet($event, 5, 'add')"
                      class="add-qty col-40"
                      ><i class="fa fa-plus"></i
                    ></a>
                    <input
                      v-model="
                        calculator.find((value) => value.key === 5).value
                      "
                      name="shiny"
                      data-sum="350"
                      min="0"
                      type="text"
                      value="1"
                      class="qty"
                    />
                    <a
                      href="#"
                      v-on:click="greet($event, 5, 'reduce')"
                      class="substract-qty col-40"
                      ><i class="fa fa-minus"></i
                    ></a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-50">
              <div href="used-car-parts.html" class="car-category">
                <img
                  data-popover=".popover-evacuator"
                  class="popover-open"
                  src="../../img/icon/7.png"
                  alt=""
                />
                <div class="color-deeporange text-thiny">ЭВАКУАТОР</div>
                <div class="popover popover-evacuator">
                  <div class="popover-inner">
                    <div class="block">
                      <p>
                        Эвакуации вашего авто по городу от точки А до Б. До 3
                        тон.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="text-extrat-thiny gray-text">
                  <!-- 100+ Car Accessories in your area -->
                  <h4 class="row">
                    <a
                      href="#"
                      v-on:click="greet($event, 7, 'add')"
                      class="add-qty col-40"
                      ><i class="fa fa-plus"></i
                    ></a>
                    <input
                      v-model="
                        calculator.find((value) => value.key === 7).value
                      "
                      data-sum="1300"
                      name="evacuator"
                      min="0"
                      type="text"
                      value="1"
                      class="qty"
                    />
                    <a
                      href="#"
                      v-on:click="greet($event, 7, 'reduce')"
                      class="substract-qty col-40"
                      ><i class="fa fa-minus"></i
                    ></a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-50">
              <div href="used-car-parts.html" class="car-category">
                <img
                  data-popover=".popover-komisar"
                  class="popover-open"
                  src="../../img/icon/8.png"
                  alt=""
                />
                <div class="color-deeporange text-thiny">
                  АВАРИЙНЫЙ КОМИССАР
                </div>
                <div class="popover popover-komisar">
                  <div class="popover-inner">
                    <div class="block">
                      <p>
                        Оформление ДТП, Помощь при получении страховых выплат.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="text-extrat-thiny gray-text">
                  <!-- 100+ Car Accessories in your area -->
                  <h4 class="row">
                    <a
                      href="#"
                      v-on:click="greet($event, 8, 'add')"
                      class="add-qty col-40"
                      ><i class="fa fa-plus"></i
                    ></a>
                    <input
                      v-model="
                        calculator.find((value) => value.key === 8).value
                      "
                      data-sum="600"
                      name="komisar"
                      min="0"
                      type="text"
                      value="1"
                      class="qty"
                    />
                    <a
                      href="#"
                      v-on:click="greet($event, 8, 'reduce')"
                      class="substract-qty col-40"
                      ><i class="fa fa-minus"></i
                    ></a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-50">
              <div href="used-car-parts.html" class="car-category">
                <img
                  data-popover=".popover-diagnostica"
                  class="popover-open"
                  src="../../img/icon/9.png"
                  alt=""
                />
                <div class="color-deeporange text-thiny">
                  ВЫЕЗДНАЯ ДИАГНОСТИКА
                </div>
                <div class="popover popover-diagnostica">
                  <div class="popover-inner">
                    <div class="block">
                      <p>Диагностика ходовой части, электрика.</p>
                    </div>
                  </div>
                </div>
                <div class="text-extrat-thiny gray-text">
                  <!-- 100+ Car Accessories in your area -->
                  <h4 class="row">
                    <a
                      href="#"
                      v-on:click="greet($event, 9, 'add')"
                      class="add-qty col-40"
                      ><i class="fa fa-plus"></i
                    ></a>
                    <input
                      v-model="
                        calculator.find((value) => value.key === 9).value
                      "
                      data-sum="750"
                      name="diagnostica"
                      min="0"
                      type="text"
                      value="1"
                      class="qty"
                    />
                    <a
                      href="#"
                      v-on:click="greet($event, 9, 'reduce')"
                      class="substract-qty col-40"
                      ><i class="fa fa-minus"></i
                    ></a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="cart-costs">
              <h4>Общая стоимость</h4>
              <div class="clear"></div>
              <a
                v-on:click="buyCalculator(sumMonth)"
                class="
                  button button-green button-fullscreen
                  half-top
                  sum-month
                  buy-wallet
                "
                ><strong>Подключиться</strong>
                <em class="color-red-dark">{{ sumMonth }}</em
                >.00<sub>р.</sub></a
              >
            </div>
          </div>
        </form>
      </div>
    </f7-block>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";

export default {
  name: "calculator",
  methods: {
    greet: function (event, i, type) {
      if (type === "add") {
        let item = this.calculator.find((value) => value.key === i);
        item.value += 1;
      } else {
        let item = this.calculator.find((value) => value.key === i);
        if (item.value > 0) {
          item.value -= 1;
        }
      }
      // console.log(this.calculator);
      this.calculatorSum();
    },
    calculatorSum: function () {
      let sum = 0;
      this.calculator.forEach((value) => {
        sum += value.value * value.price;
      });
      this.sumMonth = sum;
    },
    buyCalculator(sum) {
      let user = ApiService.user();
      this.$f7.dialog.confirm(
        `Оплатить со счета на сумму (${sum}) вашь баланс (${user.purse})`,
        "Уведомления",
        () => {
          if (sum > user.purse) {
            this.$f7.dialog.close();
            this.$f7.dialog.alert("Недостаточьно средст", "Ошибка");
          } else {
            let request = {};
            this.calculator.forEach((value) => {
              Object.assign(request, { [value.keyRequest]: value.value });
            });
            Object.assign(request, { api: true });
            Object.assign(request, { summ: this.sumMonth });
            ApiService.addCalculateBuyService(request).then((response) => {
              if (response) {
                this.$f7.dialog.alert(
                  "Услуги успешно добавлены",
                  "Уведомления"
                );
              }
            });
          }
        }
      );
    },
  },
  data: function () {
    return {
      sumMonth: 0,
      calculator: [
        {
          value: 0,
          key: 2,
          keyRequest: "ekz",
          price: 320,
        },
        {
          value: 0,
          key: 3,
          keyRequest: "yur",
          price: 180,
        },
        {
          value: 0,
          key: 4,
          keyRequest: "save",
          price: 90,
        },
        {
          value: 0,
          key: 5,
          keyRequest: "shiny",
          price: 350,
        },
        {
          value: 0,
          key: 7,
          keyRequest: "evacuator",
          price: 1300,
        },
        {
          value: 0,
          key: 8,
          keyRequest: "komisar",
          price: 600,
        },
        {
          value: 0,
          key: 9,
          keyRequest: "diagnostica",
          price: 750,
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>