<template>
  <f7-page>
    <div class="page-content" id="app-cover">
      <div class="pull-left">
        <a class="panel-open main-menu" href="#"
          ><i class="icon icon-bars"></i
        ></a>
      </div>
      <div class="clearfix"></div>
      <div class="app-cover-content">
        <div class="margin-bottom-10">
          <img
            src="../../img/tublogo.png"
            alt="Carlitos"
            width="100"
            height="100"
          />
        </div>
        <div class="margin-bottom-20" style="text-align: left">
          <div class="text-large margin-bottom-15">
            Услуги исполнителей можно оплачивать по факту их получения.
          </div>
          <div class="margin-bottom-50">
            Но для вашего спокойствия и экономии денег мы рекомендуем
            подписаться на тарифы. Так вы сможете гарантированно получить
            необходимую помощь. Подключив пакет «Основной» за 549 руб./мес., вы
            сможете каждый месяц получать услуги:
            <p>
              1 экстренная помощь на дороге («прикурить» автомобиль, подвезти
              топливо),
            </p>
            <p>
              1 шиномонтаж (ремонт прокола колеса, подкачка или замена на
              запасное колесо),
            </p>
            <p>
              1 выезд аварийного комиссара (оформление ДТП, помощь при получение
              страховых выплат).
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-50">
            <f7-button
              v-on:click="back()"
              fill
              class="color-green"
              style="background: #0aaaa0 !important; color: #fff !important"
              >Назад</f7-button
            >
          </div>
          <div class="col-50">
            <f7-button v-on:click="next()" fill class="color-green"
              >Далее</f7-button
            >
          </div>
        </div>
        <div class="row offset-top-10 margin-bottom-10">
          <div class="col-100">
            <a
              href="tel:+7(908)440-49-11"
              class="
                external
                button button-fill
                color-blue
                white-text
                text-thiny
              "
              style="background: #e96c4c !important"
            >
              Заказать сейчас
            </a>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
export default {
  name: "index_tutorial_1",
  methods: {
    next() {
      this.$f7router.navigate("/index_tutorial_3/");
    },
    back() {
      this.$f7router.back();
    },
  },
};
</script>

<style scoped>
</style>