<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Вход</div>
    </f7-navbar>
    <f7-block-title>Вход</f7-block-title>
    <f7-block strong>
      <f7-list no-hairlines-md>
        <f7-list-input
          label="Телефон"
          type="text"
          placeholder="Введите телефон"
          :value="user.email"
          @input="user.email = $event.target.value"
          clear-button
        >
          <f7-icon icon="flaticon-email" slot="media"></f7-icon>
        </f7-list-input>
        <f7-list-input
          label="Пароль"
          type="password"
          placeholder="Введите пароль"
          :value="user.password"
          @input="user.password = $event.target.value"
          clear-button
        >
          <f7-icon icon="flaticon-key" slot="media"></f7-icon>
        </f7-list-input>
      </f7-list>
      <div class="text-center margin-bottom-15">
        <a href="/reset" class="text-underline text-extrat-thiny gray-text"
          >Забыли пароль? Востановить</a
        >
      </div>
      <f7-button v-on:click="checkForm" raised fill>Войти</f7-button>
    </f7-block>
    <!--<form id="sign-in-on-system">-->
    <!--<div class="list-block margin-bottom-30 samosa">-->
    <!--<ul class="no-border">-->
    <!--<li>-->
    <!--<div class="item-content">-->
    <!--<div class="input-icon item-media"><i class="flaticon-email"></i></div>-->
    <!--<div class="item-inner no-margin">-->
    <!--<div class="item-input">-->
    <!--<input type="email" id="sign-in-email" class="text-thiny user-email" required placeholder="E-mail Адрес">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</li>-->
    <!--<li>-->
    <!--<div class="item-content">-->
    <!--<div class="input-icon item-media"><i class="flaticon-key"></i></div>-->
    <!--<div class="item-inner no-margin">-->
    <!--<div class="item-input">-->
    <!--<input type="password" id="sign-in-password" class="text-thiny user-pass" required placeholder="Пароль">-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</li>-->
    <!--</ul>-->
    <!--&lt;!&ndash;<a href="remember-password.html" class="text-underline text-extrat-thiny gray-text">Забыли пароль ?</a>&ndash;&gt;-->
    <!--</div>-->
    <!--<div class="row btn-form-group margin-bottom-10">-->
    <!--<div class="col-100">-->
    <!--<button type="submit" class="button button-fill color-black text-thiny login-btn" style="width: 100%;">Войти</button>-->
    <!--</div>-->
    <!--&lt;!&ndash;<div class="col-50">-->
    <!--<a href="restaurants.html" class="button button-fill color-facebook text-thiny">Facebook</a>-->
    <!--</div>&ndash;&gt;-->
    <!--</div>-->
    <!--<div class="text-center margin-bottom-15">-->
    <!--<a href="/register/" class="text-underline text-extrat-thiny gray-text">Нет аккаунта? Зарегистрируйтесь</a>-->
    <!--</div>-->
    <!--<div class="text-center margin-bottom-15">-->
    <!--<a href="/password-reset/" class="text-underline text-extrat-thiny gray-text">Забыли пароль? Востановить</a>-->
    <!--</div>-->
    <!--</form>-->
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";
import store from "../../vuex/storage.js";

export default {
  name: "login",
  data: () => {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  created() {
    console.log(ApiService.user());
  },
  methods: {
    checkForm: function () {
      this.$f7.dialog.preloader("Пожалуйста подождите");
      ApiService.login(this.user.email, this.user.password)
        .then(() => {
          this.$f7.dialog.close();
          store.dispatch("chancgeLoginStatus");
          this.$f7router.navigate("/", { reloadAll: true });
        })
        .catch((error) => {
          this.$f7.dialog.close();
          if (error.status == 401) {
            this.$f7.dialog.alert("Неверный логин или пароль", "Ошибка");
          }
        });
      // if (this.name && this.age) {
      // 	return true;
      // }

      // this.errors = [];
      //
      // if (!this.name) {
      // 	this.errors.push('Требуется указать имя.');
      // }
      // if (!this.age) {
      // 	this.errors.push('Требуется указать возраст.');
      // }
      //
      // e.preventDefault();
    },
  },
};
</script>

<style scoped></style>
