<template>
  <!-- App -->
  <f7-app :params="f7params">
    <f7-statusbar></f7-statusbar>
    <f7-panel side="left" left cover>
      <!-- TODO: fixme -->
      <!-- <f7-view url="/panel-left/" links-view=".view-main" /> -->
      <PanelLeft></PanelLeft>
    </f7-panel>
    <f7-view url="/" :main="true" class="ios-edges"></f7-view>
  </f7-app>
</template>
<script>
// Import Routes...
import routes from "./routes.js";
import PanelLeft from "./assets/vue/component/panel-left.vue";

let theme = "auto";
if (document.location.search.indexOf("theme=") >= 0) {
  theme = document.location.search.split("theme=")[1].split("&")[0];
}

export default {
  components: { PanelLeft },
  data() {
    return {
      f7params: {
        theme,
        routes,
        id: "io.framework7.testapp",
        panel: {
          swipe: "left",
          swipeActiveArea: 50,
        },
        dialog: {
          buttonOk: "Ок",
          buttonCancel: "Отмена",
        },
        // view: {
        //     main: true,
        //     pushState: true
        // },
      },
    };
  },
};
</script>
