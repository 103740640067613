<template>
  <f7-page>
    <f7-navbar title="Чат" back-link="Back"></f7-navbar>
    <f7-messagebar
      style="color: black"
      :placeholder="placeholder"
      ref="messagebar"
      :attachmentsVisible="attachmentsVisible"
      :sheetVisible="sheetVisible"
    >
      <f7-link
        icon-if-ios="f7:arrow_up_fill"
        icon-if-md="material:send"
        slot="inner-end"
        @click="sendMessage"
      ></f7-link>
      <f7-messagebar-attachments>
        <f7-messagebar-attachment
          v-for="(image, index) in attachments"
          :key="index"
          :image="image"
          @attachment:delete="deleteAttachment(image)"
        ></f7-messagebar-attachment>
      </f7-messagebar-attachments>
      <f7-messagebar-sheet>
        <f7-messagebar-sheet-image
          v-for="(image, index) in images"
          :key="index"
          :image="image"
          :checked="attachments.indexOf(image) >= 0"
          @change="handleAttachment"
        ></f7-messagebar-sheet-image>
      </f7-messagebar-sheet>
    </f7-messagebar>

    <f7-messages ref="messages">
      <f7-messages-title><b>Сегодня,</b> в 12:58</f7-messages-title>
      <f7-message
        v-for="(message, index) in messages"
        :key="index"
        :type="message.type"
        :text="message.body"
        :name="message.belongs_user_to.name"
        :avatar="message.avatar"
        :first="isFirstMessage(message, index)"
        :last="isLastMessage(message, index)"
        :tail="isTailMessage(message, index)"
      ></f7-message>
    </f7-messages>
  </f7-page>
</template>
<script>
import ApiService from "../common/api.service";
export default {
  props: ["navigate"],
  data() {
    return {
      messages: [{ body: "ads", user_to: "sad", type: "sent" }],
      user_to: "",
      attachments: [],
      sheetVisible: false,
      messagesData: [
        {
          type: "sent",
          text: "Здравствуйте",
        },
        {
          type: "sent",
          text: "Исполнитель взял задачу",
        },
        {
          name: "Имя пользователя",
          type: "received",
          text: "Здравствуйте!",
        },
      ],
      images: [],
      people: [
        {
          name: "Имя пользователя",
        },
        {
          name: "Имя пользователя",
        },
      ],
      answers: ["Да!"],
      responseInProgress: false,
      intervalTimer: null,
    };
  },
  created() {
    this.$f7.dialog.preloader("Пожалуйста подождите");
    this.fetchMessages(this.navigate, () => {
      this.$f7.dialog.close();
    });
    this.intervalTimer = setInterval(() => {
      this.fetchMessages(this.navigate);
    }, 10000);
  },
  destroyed() {
    console.log("destroyed");
    clearInterval(this.intervalTimer);
  },
  watch: {
    attachmentsVisible() {
      const self = this;
      self.$nextTick(() => {
        self.messagebar.resizePage();
      });
    },
    sheetVisible() {
      const self = this;
      self.$nextTick(() => {
        self.messagebar.resizePage();
      });
    },
  },
  computed: {
    attachmentsVisible() {
      const self = this;
      return self.attachments.length > 0;
    },
    placeholder() {
      const self = this;
      return self.attachments.length > 0 ? "Add comment or Send" : "Сообщения ";
    },
  },
  methods: {
    fetchMessages(id_order, colback) {
      // this.$f7.dialog.preloader('Пожалуйста подождите');
      ApiService.getMessages(id_order)
        .then((res) => {
          this.messages = res;
          this.messages.forEach((item) => {
            if (item.myMessage) {
              item.type = "sent";
            } else {
              this.user_to = item.belongs_user_to.name;
              item.type = "received";
            }
            //console.log(item, i, arr);
          });
          colback();
          // this.$f7.dialog.close();
          //console.log(res);
        })
        .catch((err) => {
          colback();
          console.log(err);
          // this.$f7.dialog.close();
        });
    },
    isFirstMessage(message, index) {
      const self = this;
      const previousMessage = self.messagesData[index - 1];
      if (message.isTitle) return false;
      if (
        !previousMessage ||
        previousMessage.type !== message.type ||
        previousMessage.name !== message.name
      )
        return true;
      return false;
    },
    isLastMessage(message, index) {
      const self = this;
      const nextMessage = self.messagesData[index + 1];
      if (message.isTitle) return false;
      if (
        !nextMessage ||
        nextMessage.type !== message.type ||
        nextMessage.name !== message.name
      )
        return true;
      return false;
    },
    isTailMessage(message, index) {
      const self = this;
      const nextMessage = self.messagesData[index + 1];
      if (message.isTitle) return false;
      if (
        !nextMessage ||
        nextMessage.type !== message.type ||
        nextMessage.name !== message.name
      )
        return true;
      return false;
    },
    sheetToggle() {
      const self = this;
      self.sheetVisible = !self.sheetVisible;
    },
    deleteAttachment(image) {
      const self = this;
      const index = self.attachments.indexOf(image);
      self.attachments.splice(index, 1)[0]; // eslint-disable-line
    },
    handleAttachment(e) {
      const self = this;
      const index = self.$$(e.target).parents("label.checkbox").index();
      const image = self.images[index];
      if (e.target.checked) {
        // Add to attachments
        self.attachments.unshift(image);
      } else {
        // Remove from attachments
        self.attachments.splice(self.attachments.indexOf(image), 1);
      }
    },
    sendMessage() {
      const self = this;
      const text = self.messagebar.getValue().replace(/\n/g, "<br>").trim();
      ApiService.sendMessages(this.navigate, text, this.user_to)
        .then(() => {
          this.messages.push({ body: text, user_to: "Я", type: "sent" });
        })
        .catch((err) => {
          console.log(err);
        });
      this.messagebar.setValue(" ");
      //console.log(this.messagebar.getValue());
      // this.$f7.dialog.preloader('Пожалуйста подождите');
      // ApiService.getMessages(id_order).then(res => {
      //     this.messages = res;
      //     this.messages.forEach((item, i, arr) => {
      //         if(item.myMessage){
      //             item.type = 'sent';
      //         }else{
      //             item.type = 'received';
      //         }
      //         //console.log(item, i, arr);
      //     });
      //     this.$f7.dialog.close();
      //     //console.log(res);
      // }).catch(err => {
      //     console.log(err);
      //     this.$f7.dialog.close();
      // });

      // const messagesToSend = [];
      // self.attachments.forEach((attachment) => {
      // 	messagesToSend.push({
      // 		image: attachment,
      // 	});
      // });
      // if (text.trim().length) {
      // 	messagesToSend.push({
      // 		text,
      // 	});
      // }
      // // Reset attachments
      // self.attachments = [];
      // // Hide sheet
      // self.sheetVisible = false;
      // // Clear area
      // self.messagebar.clear();
      // // Focus area
      // if (text.length) self.messagebar.focus();
      // // Send message
      // self.messagesData.push(...messagesToSend);
      //
      // // Mock response
      // if (self.responseInProgress) return;
      // self.responseInProgress = true;
      // setTimeout(() => {
      // 	const answer = self.answers[Math.floor(Math.random() * self.answers.length)];
      // 	const person = self.people[Math.floor(Math.random() * self.people.length)];
      // 	self.messages.showTyping({
      // 		header: `${person.name} is typing`,
      // 		avatar: person.avatar,
      // 	});
      // 	setTimeout(() => {
      // 		self.messagesData.push({
      // 			text: answer,
      // 			type: 'received',
      // 			name: person.name,
      // 			avatar: person.avatar,
      // 		});
      // 		self.messages.hideTyping();
      // 		self.responseInProgress = false;
      // 	}, 4000);
      // }, 1000);
    },
  },
  mounted() {
    this.$f7ready(() => {
      const self = this;
      self.messagebar = self.$refs.messagebar.f7Messagebar;
      self.messages = self.$refs.messages.f7Messages;
    });
  },
};
</script>