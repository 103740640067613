<template>
  <f7-page :page-content="false">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Владивосток</div>
    </f7-navbar>
    <Executer></Executer>
  </f7-page>
</template>

<script>
import Executer from "../component/executer.vue";

export default {
  components: {
    Executer,
  },
  data: () => { 
    return {}
  },
  created: function () {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style scoped></style>
