<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          class="panel-open"
          open-panel="left"
          icon="fa fa-bars"
        ></f7-link>
      </f7-nav-left>
      <div class="title">Мои задачи</div>
    </f7-navbar>
    <f7-block>
      <div class="card order" v-for="order of orders" :key="order">
        <div class="card-header" style="min-height: 30px">
          <div class="order-categories">
            Категория: {{ order.service.name }}
          </div>
        </div>
        <div class="card-content card-content-padding">
          <h5>Коментарий</h5>
          <p>{{ order.description }}</p>
          <p class="likes">
            Статус:
            <span :style="order.status == 1 ? 'color:red' : ''">{{
              getStatus(order.status)
            }}</span>
          </p>
        </div>
        <div class="card-footer">
          <a class="link" v-on:click="orderClose(order.id)" style=""
            >Отменить задачу</a
          >
          <!--                    <template v-if="order.status != 1">-->
          <!--                        <a v-on:click="chatPage(order.id)" class="link" style="margin-left: 10%;">Чат</a>-->
          <!--                    </template>-->
        </div>

        <div class="card-footer" v-if="orders.status == 2">
          <a
            v-on:click="mapExecutorPage(order.id)"
            style="border: 1px solid #ff9800; width: 100%"
            class="col button color-orange"
            >Паказать на карте</a
          >
        </div>
        <template v-if="order.status != 1">
          <div class="card-footer">
            <button
              v-on:click="orderSuccess(order.id)"
              style="border: 1px solid #4caf50"
              class="col button color-green success-order"
            >
              Подтвердить выполениния
            </button>
          </div>
        </template>
      </div>
    </f7-block>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";
export default {
  name: "myOrders",
  data() {
    return {
      orders: [],
    };
  },
  created() {
    this.$f7.dialog.preloader("Пожалуйста подождите");
    ApiService.getMyOrders()
      .then((res) => {
        this.orders = res;
        this.$f7.dialog.close();
      })
      .catch(() => {
        this.$f7.dialog.close();
      });
  },
  methods: {
    chatPage(id) {
      this.$f7router.navigate("/chat/", {
        props: {
          navigate: id,
        },
      });
    },
    mapExecutorPage(id) {
      this.$f7router.navigate("/map-executor/", {
        props: {
          id: id,
        },
      });
    },
    getStatus(value) {
      let status;
      if (value == 1) status = "Поиск исполнителя";
      if (value == 2) status = "Принята в работу";
      if (value == 3) status = "Выполнена";
      if (value == 4) status = "Отменена";
      return status;
    },
    orderClose(id) {
      this.$f7.dialog.confirm(
        "Вы уверенны что хотите отменить заявку.",
        "Уведомления",
        () => {
          ApiService.orderClose(id)
            .then((response) => {
              console.log(response);
              if (response == "1") {
                ApiService.getMyOrders()
                  .then((res) => {
                    this.orders = res;
                  })
                  .catch(() => {});

                this.$f7.dialog.confirm(
                  "Задача успешно отменена. Перейти на главную?",
                  "Уведомления",
                  () => this.$f7router.navigate("/")
                );
              }
            })
            .catch();
        }
      );
    },
    orderSuccess(id) {
      this.$f7.dialog.confirm(
        "Вы уверенны что хотите подтвердить заявку.",
        "Уведомления",
        () => {
          ApiService.orderSuccess(id)
            .then((response) => {
              console.log(response);
              if (response == "1") {
                this.$f7.dialog.alert("Задача выполнена.", "Уведомления");
                ApiService.getMyOrders()
                  .then((res) => {
                    this.orders = res;
                  })
                  .catch(() => {});
              }
            })
            .catch();
        }
      );
    },
  },
};
</script>

<style scoped></style>
