<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link class="panel-open" open-panel="left" icon="fa fa-bars"></f7-link>
      </f7-nav-left>
      <div class="title">Восстановить пароль</div>
    </f7-navbar>
    <f7-block-title>Восстановить пароль</f7-block-title>
    <f7-block strong>
      <f7-list no-hairlines-md>
        <f7-list-input type="tel" placeholder="Телефон" info="Ваш телефон пример (9998887766) без (+7, 8)" clear-button
          required validate max="10" pattern="[0-9]*" data-error-message="Введите Телефон!" :value="user.phone" @input="($event) => {
            user.phone = $event.target.value;
          }
            "></f7-list-input>
        <f7-list-input type="password" placeholder="Пароль" info="Ваш новый пароль" clear-button required validate
          max="10" data-error-message="Введите пароль!" :value="user.pass" @input="($event) => {
            user.pass = $event.target.value;
          }
            "></f7-list-input>
        <f7-row>
          <f7-col width="50">
            <f7-list-input type="text" placeholder="*******" info="Введите код" pattern="[0-9]*"
              data-error-message="Введите Код!" clear-button required validate :value="phone_code_confirm"
              @input="phone_code_confirm = $event.target.value"></f7-list-input>
          </f7-col>
          <f7-col width="50">
            <f7-button id="sendSmsBtn" style="margin-right: 16px" big fill v-on:click="sendCode()">Получить
              код</f7-button>
          </f7-col>
        </f7-row>
      </f7-list>
      <f7-button v-on:click="resetPass" raised fill>Восстановить</f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
import ApiService from "../common/api.service";

export default {
  name: "resetPass",
  data: () => {
    return {
      user: {
        phone: "",
        pass: "",
      },
      phone_code: null,
      phone_code_confirm: null,
    };
  },
  methods: {
    formatPhoneNumber(input) {
      // Убираем всё, кроме цифр
      let cleaned = input.replace(/\D/g, '');

      // Проверяем и форматируем номер
      if (cleaned.startsWith('8') && cleaned.length === 11) {
        cleaned = '7' + cleaned.slice(1);
      } else if (cleaned.startsWith('9') && cleaned.length === 10) {
        cleaned = '7' + cleaned;
      } else if (cleaned.startsWith('7') && cleaned.length === 11) {
        // Уже в нужном формате
      } else {
        throw new Error('Некорректный номер телефона');
      }

      return cleaned;
    },
    async sendCode() {
      this.$f7.dialog.alert(
        "Код отправлен. (последнии 4 цифры номера)",
        "Ожидайте звонка"
      );
      this.user.phone = this.formatPhoneNumber(this.user.phone);
      console.log("Input phone:", this.user.phone);
      const url = new URL("https://tube-city-call.pileskyd.ru/api/call");
      url.searchParams.set("phone", this.user.phone);
      const response = await fetch(url.toString());
      const data = await response.json();

      if (data.status === "ERROR") {
        this.$f7.dialog.confirm(
          "Ошибка отправки",
          "Здравствуйте, если Вам не поступает звонок обратитесь к администратору!"
        );

        return;
      }
      this.phone_code = data.code;
    },
    resetPass() {
      if (this.phone_code != this.phone_code_confirm) {
        this.$f7.dialog.confirm(
          "Кода подтверждения",
          "Не верный код подтверждения."
        );
        return 0;
      }

      // reset password request
      ApiService.resetPass(this.user)
        .then(() => {
          this.$f7.dialog.close();
          this.$f7router.navigate("/login/");
        })
        .catch((error) => {
          this.$f7.dialog.close();
          if (error.status == 401) {
            this.$f7.dialog.alert("Неверный логин или пароль", "Ошибка");
          }
        });
      this.countSendSms += 1;
      // console.log(this.state.countSendSms);
    },
  },
};
</script>

<style scoped>
.list li {
  list-style-type: none;
}
</style>